import React, { useState, useEffect } from 'react';

const GenreSelector = ({ genre, setGenre }) => {
    const [genres, setGenres] = useState([]);

    useEffect(() => {
        const fetchGenres = async () => {
            try {
                const response = await fetch('/api/genres');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setGenres(data); 
            } catch (error) {
                console.error('Error fetching genres:', error);
            }
        };

        fetchGenres();
    }, []);

    return (
        <select
            value={genre}
            onChange={(e) => setGenre(e.target.value)}
            className="w-full bg-secondary text-text md:w-1/4 px-4 py-2 border border-primary rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
            <option value="">All Genres</option>
            {genres.map((g, index) => (
                <option key={index} value={g.genre}>
                    {g.genre}
                </option>
            ))}
        </select>
    );
};

export default GenreSelector;
