import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from "react-icons/fi"; 
import { useAuth } from '../contexts/AuthContext'; 

const Dropdown = ({ mobileMode, closeMenu }) => {
  const { isLoggedIn, logout } = useAuth(); 
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const menuItems = isLoggedIn
    ? [
        { label: 'Profile', path: '/me' },
        { label: 'Leaderboard', path: '/leaderboard' },
        { label: 'Search', path: '/search' },
        { label: 'Shop', path: '/shop' },
        { label: 'Referral', path: '/referral' },
        { label: 'Settings', path: '/settings' },
        { label: 'Donate', path: '/donate' },
        { label: 'Logout', action: logout },
      ]
    : [
        { label: 'Login', path: '/login' },
        { label: 'Register', path: '/register' },
        { label: 'Search', path: '/search' },
        { label: 'Donate', path: '/donate' },
      ];

  if (mobileMode) {
    return (
      <>
        {menuItems.map((item, index) =>
          item.action ? (
            <button
              key={index}
              className="text-lg text-text cursor-pointer hover:text-primary transition-colors duration-200"
              onClick={() => {
                item.action();
                closeMenu();
              }}
            >
              {item.label}
            </button>
          ) : (
            <Link
              key={index}
              to={item.path}
              className="text-lg text-text cursor-pointer hover:text-primary transition-colors duration-200"
              onClick={closeMenu}
            >
              {item.label}
            </Link>
          )
        )}
      </>
    );
  }

  return (
    <div className="relative z-50 inline-block text-left" ref={dropdownRef}>
      <button onClick={toggleDropdown} className="text-text mt-1">
        <FiMenu size={28} />
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="py-1" role="none">
            {menuItems.map((item, index) =>
              item.action ? (
                <button
                  key={index}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={() => {
                    item.action();
                    setIsOpen(false);
                  }}
                >
                  {item.label}
                </button>
              ) : (
                <Link
                  key={index}
                  to={item.path}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => setIsOpen(false)}
                >
                  {item.label}
                </Link>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
