import React, { useState } from "react";

const About = () => {
  const [activeTab, setActiveTab] = useState("us");

  const renderContent = () => {
    switch (activeTab) {
      case "us":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About Us</h2>
            <p className="text-lg text-text">
              We are a passionate team of anime enthusiasts who believe in creating a platform that connects fans from around the world. We might not be pros, but we hope that our platform will bring you enough joy to stay. 
                We hope to make our platform somewhat competitive, to show that we are dedicated to watch our favourite anime.
                There is unfortunately no real way to contact us for privacy reasons, but do not worry. If you find any bugs, post about it on reddit or twitter, we will take notice. We will also continuously work to make this platform better.</p>
          </div>
        );
      case "aboutProject":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About K-Anime</h2>
            <p className="text-lg text-text">
              K-Anime is dedicated to be somewhat competitive. 
              We also offer all kinds of anime, you think of it? We probably have it. 
              Why? Because who doesn't like a little challenge. Not to mention, by being in the top 10 you will be seen across the globe.
            </p>
          </div>
        );
        case "Leaderboard":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About Leaderboard</h2>
            <p className="text-lg text-text">
              The leaderboard is designed to give you (yes you) a way of showing yourself. You can link your socials in your bio, the higher you are the better for you. 
              After winning the leaderboard whether weekly or monthly, you will gain premium for a week or a month. Depending which one you won, of course.
              </p>
          </div>
        );
        case "Premium":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About Premium</h2>
            <p className="text-lg text-text">
              What benefits does Premium give? Premium gives you an xp boost, you will be able to change your username and have an animated banner or even just a picture in it.
              Premium does not automatically unlock all themes.
              </p>
          </div>
        );
        case "Xp":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About Xp</h2>
            <p className="text-lg text-text">
              What is Xp? How to gain Xp? It's really simple! 
              You gain xp by every minute you have watched anime. You can use it to buy items from the shop. Xp is just your currency. But xp will also be used to determine your leaderboard position.
              Once you spend it, it will get deducted from your xp. 
              Be careful how you spend it!
              </p>
          </div>
        );
        case "News":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About News</h2>
            <p className="text-lg text-text">
              News will be like admin updates from us. Whenever we have anything to say, we will update it. Simple, nothing complicated here.~
              </p>
          </div>
        );
        case "Random":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About Random</h2>
            <p className="text-lg text-text">
              Random is a place where you can try your luck with anime. A random anime will be shown to you, you can decide whether to watch it or not.
              </p>
          </div>
        );
        case "Shop":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About Shop</h2>
            <p className="text-lg text-text">
              The shop is a magical place. But no worries, we will try to update the shop items with next updates and so on.
              </p>
          </div>
        );
        case "Donation/Monetization":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About Donations/Monetization</h2>
            <p className="text-lg text-text">
              We would ask you to please turn off ad blocker for us, since ads are the primary income source to keep this project up.
              But of course we do accept donations too, if you want to support us. If you do want to support us, but don't have money? 
              No problem! Feel free to share our platform to your friends to help us out.
              </p>
          </div>
        );
        case "Future":
        return (
          <div className="p-5">
            <h2 className="text-3xl font-bold mb-4">About Future</h2>
            <p className="text-lg text-text">
              If this project actually gets big, we will add a watch2gether feature and basic chatting so you can connect with your followers.
              Other possible features will not be disclosed for now. 
              </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex w-full gap-4 mt-5 h-[600px]">
      <div className="w-1/4 ml-2 bg-primary h-full rounded-lg shadow-lg p-4">
        <ul className="space-y-4">
          <li>
            <button
              onClick={() => setActiveTab("us")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "us" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About Us
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("aboutProject")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "aboutProject" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About K-Anime
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("Leaderboard")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "Leaderboard" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About Leaderboard
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("Premium")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "Premium" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About Premium
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("Xp")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "Xp" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About Xp
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("News")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "News" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About News
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("Random")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "Random" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About Random
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("Shop")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "Shop" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About Shop
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("Donation/Monetization")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "Donation/Monetization" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About Donation/Monetization
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("Future")}
              className={`w-full text-left px-4 py-2 rounded-lg font-semibold ${
                activeTab === "Future" ? "bg-secondary scale-105 text-text" : "bg-secondary text-text"
              }`}
            >
              About Future
            </button>
          </li>
        </ul>
      </div>
      <div className="w-[72%] bg-primary h-full rounded-lg shadow-lg p-4">
        {renderContent()}
      </div>
    </div>
  );
};

export default About;
