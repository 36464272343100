// SettingsSidebar.js
import React from 'react';

const SettingsSidebar = ({ selectedCategory, onSelectCategory }) => {
  const categories = [
    { name: 'Account', key: 'account' },
    { name: 'Profile', key: 'profile' },
    { name: 'Privacy', key: 'privacy' },
    { name: 'Themes', key: 'themes' },
    { name: 'Decorations', key: 'decorations' },
    { name: 'Social Media', key: 'socialmedia' },
  ];

  return (
    <div className="w-full md:w-1/4 p-5 space-y-3">
      <h2 className="text-xl font-semibold">Settings</h2>
      <ul className="space-y-2">
        {categories.map((category) => (
          <li
            key={category.key}
            className={`cursor-pointer p-2 rounded-md transition-colors ${
              selectedCategory === category.key
                ? 'text-text bg-primary'
                : 'hover:text-text hover:bg-accent'
            }`}
            onClick={() => onSelectCategory(category.key)}
          >
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingsSidebar;
