import React, { useState } from 'react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');  
  const [show2FA, setShow2FA] = useState(false);  
  const [otp, setOtp] = useState('');  
  const [showResetPassword, setShowResetPassword] = useState(false);  
  const [newPassword, setNewPassword] = useState('');  
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const onEmailSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    try {
      const response = await fetch('/api/users/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),  
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('2FA code sent. Please check your authenticator.');
        setShow2FA(true); 
      } else {
        setError(data.error || 'Email not found.');
      }
    } catch (error) {
      setError('An unexpected error occurred. Please try again.');
    }
  };

  const onOtpSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
  
  
    try {
      const response = await fetch('/api/users/verify-2fa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }), 
      });
  
      const data = await response.json();
      if (response.ok) {
        setMessage('2FA code verified. You can now reset your password.');
        setShowResetPassword(true);
      } else {
        console.error('Error response:', data);
        setError(data.error || 'Invalid 2FA code.');
      }
    } catch (error) {
      setError('An unexpected error occurred. Please try again.');
    }
  };
  

  const onPasswordResetSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    try {
      const response = await fetch('/api/users/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, newPassword }),  
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Password has been reset successfully. You can now log in with the new password.');
      } else {
        setError(data.error || 'Error resetting password.');
      }
    } catch (error) {
      setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
      <h2>Forgot Password</h2>
      <form onSubmit={onEmailSubmit}>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>
            Enter your email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          />
        </div>
        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            backgroundColor: '#007BFF',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>

      {show2FA && (
        <form onSubmit={onOtpSubmit} style={{ marginTop: '20px' }}>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="otp" style={{ display: 'block', marginBottom: '5px' }}>
              Enter your 2FA code:
            </label>
            <input
              type="text"
              id="otp"
              value={otp}  
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter 2FA code"
              required
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '16px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />
          </div>
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              backgroundColor: '#28a745',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Verify 2FA Code
          </button>
        </form>
      )}

      {showResetPassword && (
        <form onSubmit={onPasswordResetSubmit} style={{ marginTop: '20px' }}>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="newPassword" style={{ display: 'block', marginBottom: '5px' }}>
              Enter your new password:
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              required
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '16px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />
          </div>
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              backgroundColor: '#ffc107',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Reset Password
          </button>
        </form>
      )}

      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default ForgotPassword;
