import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import sakuraImage from '../images/sakuralogo.png';
import midnightImage from '../images/midnightlogo.png';
import oceanImage from '../images/oceanlogo.png';
import sunsetImage from '../images/sunsetlogo.png';
import logo from '../images/highres.png';
import { useTheme } from '../contexts/ThemeContext';

const allThemes = [
  { name: 'Default', key: 'light', image: logo }, 
  { name: 'Sakura', key: 'sakura', image: sakuraImage },
  { name: 'Midnight', key: 'midnight', image: midnightImage },
  { name: 'Ocean', key: 'ocean', image: oceanImage },
  { name: 'Sunset', key: 'sunset', image: sunsetImage },
];

const Appearance = () => {
  const { token } = useAuth();
  const [availableThemes, setAvailableThemes] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setTheme, currentTheme } = useTheme();

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await fetch('/api/users/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        if (data && data.unlockedThemes) {
          const unlockedThemes = data.unlockedThemes;
          const filteredThemes = allThemes.map((theme) => ({
            ...theme,
            unlocked: theme.key === 'light' || unlockedThemes.includes(theme.key),
          }));
          setAvailableThemes(filteredThemes);
        }
      } catch (err) {
        setError('Error loading themes');
        console.error(err);
      }
    };

    fetchUserData();
  }, [token, navigate]);

  const handleClick = (themeKey, unlocked) => {
    if (unlocked) {
      setTheme(themeKey);
    } else {
      navigate('/shop');
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4 md:p-8 bg-opacity-60 rounded-lg shadow-lg">
      <h2 className="text-3xl font-semibold mb-8 text-center">Appearance Settings</h2>
      {error && <p className="text-red-500 text-center mb-6">{error}</p>}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {availableThemes.map((theme) => (
          <div
            key={theme.key}
            className={`theme-option p-4 border rounded-lg cursor-pointer transition-transform transform hover:scale-105 ${
              theme.unlocked ? 'border-primary' : 'border-gray-300'
            } shadow-md`}
            onClick={() => handleClick(theme.key, theme.unlocked)}
          >
            <img
              src={theme.image}
              alt={theme.name}
              className="w-full h-28 object-cover rounded-md"
            />
            <h3 className="text-center mt-3 font-semibold">{theme.name}</h3>
            <button
              className={`mt-2 w-full py-2 ${
                theme.unlocked ? 'bg-primary' : 'bg-secondary'
              } text-white rounded transition-colors ${
                theme.key === currentTheme ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={theme.key === currentTheme}
            >
              {theme.key === currentTheme ? 'Applied' : theme.unlocked ? 'Apply' : 'Unlock'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Appearance;
