import React, { createContext, useContext, useState, useEffect } from "react";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [currentTheme, setCurrentTheme] = useState("light");

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    const savedDarkMode = localStorage.getItem("dark-mode") === "true";

    setCurrentTheme(savedTheme);
    setIsDarkMode(savedDarkMode);

    applyTheme(savedTheme, savedDarkMode);
  }, []);

  useEffect(() => {
    applyTheme(currentTheme, isDarkMode);
  }, [currentTheme, isDarkMode]);

  const applyTheme = (theme, darkMode) => {
    const appliedTheme = darkMode ? `${theme}-dark` : theme;
    document.documentElement.setAttribute("data-theme", appliedTheme);
  };

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    localStorage.setItem("dark-mode", newDarkMode);
  };

  const setTheme = (themeKey) => {
    setCurrentTheme(themeKey);
    localStorage.setItem("theme", themeKey);
  };

  return (
    <ThemeContext.Provider
      value={{ isDarkMode, currentTheme, toggleDarkMode, setTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
