import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const useAnimeDetails = () => {
  const { id } = useParams();
  const [anime, setAnime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      const fetchAnime = async () => {
          try {
              const response = await fetch(`/api/anime/${id}`);
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setAnime(data);
          } catch (error) {
              setError(error);
          } finally {
              setLoading(false);
          }
      };

      fetchAnime();
  }, [id]);

  return { anime, loading, error };
};

export default useAnimeDetails;
