import React, { useState, useEffect } from "react";
import Cloud from "../components/Clouds";
import ConnectingLine from "../components/Lines";

const ReferralRoadmap = () => {
  const [referralCount, setReferralCount] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [milestones, setMilestones] = useState([
    { count: 0, reward: "Starting Point", unlocked: true },
    { count: 5, reward: "200 XP", unlocked: false },
    { count: 15, reward: "1 week premium", unlocked: false },
    { count: 50, reward: "1 month premium", unlocked: false },
    { count: 100, reward: "Special Decoration", unlocked: false },
  ]);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchReferralData = async () => {
      try {
        const response = await fetch("/api/users/referral-data", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setReferralCount(data.referralCount);
          setReferralCode(data.referralCode);

          const updatedMilestones = milestones.map((milestone) => ({
            ...milestone,
            unlocked: data.referralCount >= milestone.count,
          }));

          setMilestones(updatedMilestones);
        } else {
          throw new Error("Failed to fetch referral data.");
        }
      } catch (error) {
        console.error("Error fetching referral data:", error);
      }
    };

    fetchReferralData();

    // Add a resize listener to detect screen size changes
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [milestones]);

  const handleCopyReferralCode = () => {
    navigator.clipboard.writeText(referralCode).then(() => {
      alert("Referral code copied to clipboard!");
    });
  };

  const getNextMilestone = () => {
    const nextMilestone = milestones.find(m => !m.unlocked);
    if (nextMilestone) {
      return {
        referralsNeeded: nextMilestone.count - referralCount,
        reward: nextMilestone.reward,
      };
    }
    return null;
  };

  const nextMilestone = getNextMilestone();

  return (
    <div className="max-w-4xl mx-auto p-4 relative">
      <h2 className="text-2xl font-bold text-center mb-6">Referral Roadmap</h2>

      <div className="text-center mb-4">
        <p className="text-base">
          <strong>{referralCount}</strong> referrals so far! Keep going to unlock rewards.
        </p>
        <p className="text-sm">
          Your referral code: <strong>{referralCode}</strong>
        </p>
      </div>

      <div className="text-center mt-6">
        <button
          onClick={handleCopyReferralCode}
          className="px-4 py-2 bg-primary text-text rounded text-sm"
        >
          Copy Referral Code
        </button>
      </div>

      {isSmallScreen ? (
        <div className="mt-10 text-center">
          <Cloud
            count={referralCount}
            reward={`You have ${referralCount} referrals`}
            unlocked={true}
          />
          {nextMilestone && (
            <p className="mt-4 text-lg">
              Get {nextMilestone.referralsNeeded} more referrals to unlock:{" "}
              <strong>{nextMilestone.reward}</strong>
            </p>
          )}
        </div>
      ) : (
        <div className="relative w-full mt-18 z-10 h-64">
          <div className="absolute z-10" style={{ top: "3rem", left: "-8rem" }}>
            <Cloud
              count={milestones[0].count}
              reward={milestones[0].reward}
              unlocked={milestones[0].unlocked}
            />
          </div>
          <div className="absolute z-10" style={{ top: "12rem", left: "8rem" }}>
            <Cloud
              count={milestones[1].count}
              reward={milestones[1].reward}
              unlocked={milestones[1].unlocked}
            />
          </div>
          <div className="absolute z-10" style={{ top: "5rem", left: "24rem" }}>
            <Cloud
              count={milestones[2].count}
              reward={milestones[2].reward}
              unlocked={milestones[2].unlocked}
            />
          </div>
          <div className="absolute z-10" style={{ top: "11rem", left: "40rem" }}>
            <Cloud
              count={milestones[3].count}
              reward={milestones[3].reward}
              unlocked={milestones[3].unlocked}
            />
          </div>
          <div className="absolute z-10" style={{ top: "7rem", left: "56rem" }}>
            <Cloud
              count={milestones[4].count}
              reward={milestones[4].reward}
              unlocked={milestones[4].unlocked}
            />
          </div>

          <ConnectingLine
            startX={20}
            startY={60}
            endX={200}
            endY={180}
            controlOffsetX={120}
            controlOffsetY={60}
          />
          <ConnectingLine
            startX={270}
            startY={250}
            endX={480}
            endY={100}
            controlOffsetX={100}
            controlOffsetY={50}
          />
          <ConnectingLine
            startX={430}
            startY={100}
            endX={750}
            endY={220}
            controlOffsetX={150}
            controlOffsetY={60}
          />
          <ConnectingLine
            startX={800}
            startY={220}
            endX={1000}
            endY={180}
            controlOffsetX={100}
            controlOffsetY={60}
          />
        </div>
      )}
    </div>
  );
};

export default ReferralRoadmap;
