import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Box from "../components/Box";

const NotFound = () => {
  const navigateTo = useNavigate();
  return (
    <Box width="full" height="screen">
      <div className="w-full max-w-xs mx-auto p-6 bg-secondary bg-opacity-60 rounded-lg shadow-lg text-center">
        <h1 className="text-6xl font-bold text-text">404</h1>
        <h2 className="mt-4 text-lg font-semibold text-text">Page Not Found</h2>
        <p className="mt-2 text-sm mb-5">The page you're looking for doesn't exist.</p>
        <Button
          action={() => navigateTo("/")}
          text={"Go back to front page"}
          isPrimary={true}
        />
      </div>
    </Box>
  );
};

export default NotFound;
