import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext'; 
import { useNavigate } from 'react-router-dom';

const SocialMediaSettings = () => {
  const { token } = useAuth(); 
  const navigate = useNavigate();
  const [socialMedia, setSocialMedia] = useState({
    instagram: "",
    twitter: "",
    discord: "",
    reddit: "",
    spotify: "",
    github: "",
    paypal: "",
    xbox: "",
    playstation: "",
    tiktok: "",
  });
  const [activePlatform, setActivePlatform] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await fetch('/api/users/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        if (data && data.socialMedia) {
          setSocialMedia(data.socialMedia);
        }
      } catch (err) {
        setError('Error fetching social media links');
        console.error(err);
      }
    };

    fetchUserData();
  }, [token, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSocialMedia((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRemove = (platform) => {
    setSocialMedia((prev) => ({
      ...prev,
      [platform]: "", 
    }));
    setActivePlatform(null); 
  };

  const handleSave = async () => {
    try {
      const response = await fetch('/api/users/update-social-media', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ socialMedia }),
      });

      if (!response.ok) {
        throw new Error('Failed to update social media links');
      }

      await response.json();
      setSuccessMessage('Social media links updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError('Error updating social media links');
      console.error(err);
    }
  };

  const handlePlatformClick = (platform) => {
    setActivePlatform((prev) => (prev === platform ? null : platform));
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4 md:p-8 bg-secondary bg-opacity-60 rounded-lg shadow-lg">
      <h2 className="text-3xl font-semibold mb-6 text-center text-text">Social Media Settings</h2>
      
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
      {successMessage && <p className="text-green-500 mb-4 text-center">{successMessage}</p>}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {Object.keys(socialMedia).map((platform) => (
          <div key={platform} className="flex flex-col mb-4">
            <button
              onClick={() => handlePlatformClick(platform)}
              className="w-full px-4 py-2 bg-primary text-text rounded-lg transition-transform transform hover:scale-105"
            >
              {platform.charAt(0).toUpperCase() + platform.slice(1)}
            </button>
            {activePlatform === platform && (
              <div className="flex flex-col mt-2">
                <input
                  id={platform}
                  name={platform}
                  value={socialMedia[platform]}
                  onChange={handleInputChange}
                  className="p-2 border rounded-md text-text"
                  placeholder={`Enter your ${platform} username`}
                />
                <button
                  onClick={() => handleRemove(platform)}
                  className="mt-2 px-4 py-2 bg-red-500 text-white rounded-lg transition-transform transform hover:scale-105"
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      <button
        onClick={handleSave}
        className="mt-4 w-full px-4 py-2 bg-secondary text-text rounded-lg transition-transform transform hover:scale-105"
      >
        Save Changes
      </button>
    </div>
  );
};

export default SocialMediaSettings;
