import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const sanitizeTitleForURL = (title) => {
  return title.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
};

const EpisodesList = ({ animeId }) => {
  const [episodes, setEpisodes] = useState([]);
  const [watchedEpisodes, setWatchedEpisodes] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const response = await fetch(`/api/episodes/${animeId}/episodes`);
        if (!response.ok) {
          throw new Error('Failed to fetch episodes');
        }
        const data = await response.json();
        setEpisodes(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const watched = JSON.parse(localStorage.getItem('watchedEpisodes') || '[]');
    setWatchedEpisodes(new Set(watched));

    if (animeId) fetchEpisodes();
  }, [animeId]);

  const markEpisodeAsWatched = (episodeNumber) => {
    setWatchedEpisodes((prev) => {
      const updatedWatched = new Set(prev);
      updatedWatched.add(episodeNumber);
      localStorage.setItem('watchedEpisodes', JSON.stringify([...updatedWatched]));
      return updatedWatched;
    });
  };

  const handleEpisodeClick = (episode) => {
    const sanitizedTitle = sanitizeTitleForURL(episode.title);
    navigate(`/anime/${animeId}/${sanitizedTitle}`);
    markEpisodeAsWatched(episode.episodeNumber); 
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredEpisodes = episodes.filter((ep) =>
    ep.episodeNumber.toString().includes(searchTerm)
  );

  if (loading) return <p>Loading episodes...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="w-full p-4 bg-primary rounded-md shadow-md max-h-96 overflow-y-auto">
      <input
        type="text"
        placeholder="Find episode number"
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 rounded-md w-full bg-primary text-text focus:ring-2 focus:ring-accent focus:border-accent"
      />
      <div className="grid grid-cols-[repeat(auto-fill,_minmax(50px,_1fr))] gap-4">
        {filteredEpisodes.map((episode, index) => (
          <button
            key={index}
            className={`w-16 h-16 rounded-md border-2 text-text ${
              watchedEpisodes.has(episode.episodeNumber)
                ? 'bg-accent border-accent text-primary' 
                : 'border-transparent text-text'
            } bg-secondary hover:bg-primary`}
            onClick={() => handleEpisodeClick(episode)}
          >
            {`Ep. ${episode.episodeNumber}`}
          </button>
        ))}
      </div>
    </div>
  );
};

export default EpisodesList;
