import React from 'react';
import { useNavigate } from 'react-router-dom';

const transformIdToName = (id) => {
    return id
        .replace(/-/g, ' ') 
        .split(' ')        
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
        .join(' ');       
};

const AnimeList = ({ animes }) => {
    const navigate = useNavigate();

    const handleAnimeClick = (id) => {
        navigate(`/anime/${id}`); 
    };

    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {animes.map((anime, index) => (
                <div
                    key={index}
                    className="bg-secondary rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105 hover:z-10"
                    onClick={() => handleAnimeClick(anime.id)}
                >
                    <img
                        src={anime.image}
                        alt={transformIdToName(anime.id) || 'Unknown Title'} 
                        className="w-full h-50 object-cover rounded-t-lg"
                    />
                    <div className="p-2">
                        <h3 className="text-sm font-bold text-text mb-1 text-center truncate">
                            {transformIdToName(anime.id) || 'Unknown Title'} 
                        </h3>
                        <p className="text-xs text-center text-gray-500 mb-1">
                            {anime.types || 'Unknown Genre'}
                        </p>
                        <p className="text-xs text-center text-gray-500">
                            Episodes: {anime.totalEpisodes !== undefined ? anime.totalEpisodes : 'N/A'}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};


export default AnimeList;
