import React from "react";

const ConnectingLine = ({ startX, startY, endX, endY, controlOffsetX = 100, controlOffsetY = 50 }) => {


  return (
    <svg
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "visible",
      }}
      width="100%"
      height="100%"
    >
      <path
        d={`M ${startX},${startY} C ${startX + controlOffsetX},${startY - controlOffsetY} ${endX - controlOffsetX},${endY + controlOffsetY} ${endX},${endY}`}
        stroke="black"
        strokeWidth="3"
        strokeDasharray="8 5"
        fill="none"
      />
    </svg>
  );
};

export default ConnectingLine;
