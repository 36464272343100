import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import decorations from '../components/decoration'; // Import your decorations

const Comments = ({ animeId }) => {
  const { user, isLoggedIn } = useAuth();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(`/api/comments/${animeId}`);
        if (!response.ok) throw new Error('Failed to fetch comments');
        const data = await response.json();
        setComments(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchComments();
  }, [animeId]);

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    setLoading(true);
    try {
      const response = await fetch('/api/comments/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          animeId,
          content: newComment,
          username: isLoggedIn ? user.username : 'Anonymous',
        }),
      });

      if (!response.ok) throw new Error('Failed to add comment');

      const addedComment = await response.json();
      setComments((prevComments) => [...prevComments, addedComment.comment]);
      setNewComment('');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await fetch(`/api/comments/${commentId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (!response.ok) throw new Error('Failed to delete comment');

      setComments((prevComments) => prevComments.filter((comment) => comment._id !== commentId));
    } catch (err) {
      console.error(err);
    }
  };

  const getDecoration = (currentDecoration) => {
    const decorationArray = Array.isArray(decorations) ? decorations : Object.values(decorations);
    return decorationArray.find((deco) => deco.key === currentDecoration);
  };

  return (
    <div className="comments-section mt-6 bg-primary p-4 rounded-md shadow-md">
      <h3 className="text-xl font-semibold text-text mb-4">Comments</h3>

      <div className="comments-list space-y-4">
        {comments.length === 0 ? (
          <p className="text-text">No comments yet. Be the first to comment!</p>
        ) : (
          comments.map((comment) => {
            const decoration = getDecoration(comment.currentDecoration);
            return (
              <div key={comment._id} className="flex flex-col gap-2 p-2 bg-secondary rounded-md">
                <div className="flex items-start gap-4">
                  <div className="relative">
                    {decoration && (
                      <img
                        src={decoration.image}
                        alt="User decoration"
                        className="absolute inset-0 z-10 w-full h-full transform scale-125"
                        style={{ zIndex: 1 }}
                        onError={(e) => { e.target.style.display = 'none'; }}
                      />
                    )}
                    <img
                      src={comment.pfp}
                      alt={comment.username}
                      className="relative w-10 h-10 rounded-full object-cover"
                    />
                  </div>
                  <div className="flex-grow">
                    <div className="flex items-center justify-between">
                      <p className="text-text font-bold">{comment.username}</p>
                      <p className="text-sm text-text">{new Date(comment.createdAt).toLocaleString()}</p>
                    </div>
                    <p className="text-text mt-1">{comment.content}</p>
                  </div>
                </div>
                {isLoggedIn && user.username === comment.username && (
                  <button
                    onClick={() => handleDeleteComment(comment._id)}
                    className="text-sm text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                )}
              </div>
            );
          })
        )}
      </div>

      <div className="add-comment mt-4">
        <textarea
          className="w-full p-2 rounded-md bg-secondary text-text focus:ring-2 focus:ring-accent focus:border-accent"
          placeholder={isLoggedIn ? "Write your comment..." : "Login to leave a comment"}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          rows="4"
          disabled={!isLoggedIn}
        />

        {isLoggedIn ? (
          <button
            className={`mt-2 p-2 rounded-md text-text bg-accent ${loading && 'opacity-50 cursor-not-allowed'}`}
            onClick={handleAddComment}
            disabled={loading || !newComment.trim()}
          >
            {loading ? 'Posting...' : 'Post Comment'}
          </button>
        ) : (
          <p className="mt-2 text-text">Please log in to post a comment.</p>
        )}
      </div>
    </div>
  );
};

export default Comments;
