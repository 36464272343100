import React, { useMemo } from "react";
import { FiCheck, FiX } from "react-icons/fi";

const Cloud = ({ count, reward, unlocked, customStyles }) => {
  const animationDelay = useMemo(() => {
    return `${Math.random() * 2 + 1}s`;
  }, []);

  return (
    <div
      className="relative flex items-left justify-center pulse-cloud"
      style={{ width: '120px', height: '80px', animationDelay, ...customStyles }}
    >
      <div className="absolute w-14 h-14 bg-white rounded-full top-0 left-2 shadow-md"></div>
      <div className="absolute w-14 h-14 bg-white rounded-full bottom-16 right-1 shadow-md"></div>
      <div className="absolute w-14 h-14 bg-white rounded-full bottom-16 right-10 shadow-md"></div>
      <div className="absolute w-20 h-20 bg-white rounded-full bottom-2 right-2 shadow-lg"></div>
      <div className="absolute w-20 h-20 bg-white rounded-full bottom-2 left-20 shadow-lg"></div>
      <div className="absolute w-16 h-16 bg-white rounded-full top-8 left-3 shadow-lg"></div>

      <div className="relative z-10 flex flex-col items-center ml-3">
        <div className="text-sm font-bold text-gray-800">{count} Referrals</div>
        <div className="text-xs text-gray-600">
          <strong>{reward}</strong>
        </div>
        {unlocked ? (
          <FiCheck className="text-green-500 text-2xl mt-2" />
        ) : (
          <FiX className="text-red-500 text-2xl mt-2" />
        )}
      </div>

      <style jsx>{`
        @keyframes pulseCloud {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.05);
          }
          100% {
            transform: scale(1);
          }
        }

        .pulse-cloud {
          animation: pulseCloud 3s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default Cloud;
