import React, { useState, useEffect } from 'react';
import standardpfp from '../images/standardpfp.jpeg';
import decorations from '../components/decoration';
import { Link } from 'react-router-dom';

const MiniProfile = ({ username, type }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`/api/users/${username}`);
        if (!response.ok) {
          throw new Error(`User not found: ${response.status}`);
        }
        const data = await response.json();
        setUserData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [username]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!userData || !userData.leaderboardVisibility) return null;

  const {
    pfp,
    bg,
    xp,
    bgColor,
    currentDecoration,
    xpWeekly,
    xpMonthly,
    leaderboardNameVisibility,
  } = userData;

  const displayName = leaderboardNameVisibility ? username : 'Anonymous';
  const profilePic = pfp || standardpfp;
  const decoration = decorations.find(deco => deco.key === currentDecoration);
  const displayedXP = type === 'weekly' ? xpWeekly : type === 'monthly' ? xpMonthly : xp;
  const isGif = bg && bg.endsWith('.gif');

  return (
    <div className="w-64 rounded-lg shadow-md overflow-hidden text-white"> 
      <div className="relative">
        {bg ? (
          isGif ? (
            <img
              src={bg}
              alt="background gif"
              className="w-full h-24 object-cover"
            />
          ) : (
            <div
              className="w-full h-24 bg-cover bg-center"
              style={{ backgroundImage: `url(${bg})` }}
            />
          )
        ) : (
          <div
            className="w-full h-24"
            style={{
              backgroundColor: bgColor || '#ff007c',
            }}
          />
        )}

        <div className="relative">
          <img
            src={profilePic}
            alt={`Profile of ${username}`}
            className="w-16 h-16 rounded-full absolute bottom-3 left-3" 
          />
          {decoration && (
            <img
              src={decoration.image}
              alt={`${currentDecoration} decoration`}
              className="absolute bottom-2 left-2 w-20 h-20" 
              style={{
                transform: 'translate(-5%, 2%)',
              }}
            />
          )}
        </div>
      </div>
      <div className="bg-secondary p-3 pt-8"> 
        <div className="text-md text-text font-bold">
          <Link to={`/profile/${username}`} className="hover:underline">
            {displayName}
          </Link>
        </div>
        <div className="flex justify-between mt-1">
          <span className="text-sm text-text">{displayedXP} XP</span> 
        </div>
      </div>
    </div>
  );
};

export default MiniProfile;
