import React, { useState } from "react";
import Button from "../components/Button";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth(); 
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false); 

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState); 
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/api/users/login", { 
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        login(data.token); 
        navigate("/"); 
      } else {
        setError(data.error || "An unexpected error occurred.");
      }
    } catch (err) {
      console.error("Error:", err);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="flex justify-center items-start w-full h-screen pt-24"> {/* Adjusted vertical alignment */}
      <div className="w-full max-w-xs mx-auto p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold text-text text-center mb-4">Login</h2>
        {error && <p className="text-red-500 text-center text-sm">{error}</p>}
        <form onSubmit={onSubmit} className="space-y-3">
          <div>
            <label className="block text-text text-sm font-semibold mb-1" htmlFor="email">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              className="w-full px-3 py-2 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary text-black"
              placeholder="you@example.com"
            />
          </div>
          <div>
            <label className="block text-text text-sm font-semibold mb-1" htmlFor="password">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"} 
                id="password"
                value={password}
                onChange={onChange}
                className="w-full px-3 py-2 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary text-black"
                placeholder="********"
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash className="text-gray-600"/> : <FaEye className="text-gray-600"/>} 
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <a href="/forgot-password" className="text-sm text-text hover:underline">
              Forgot Password?
            </a>
          </div>
          <Button text="Login" isPrimary={true} width="full" />
        </form>
        <p className="text-center text-xs text-text mt-4">
          Don’t have an account?{" "}
          <a href="/register" className="text-text hover:underline">
            Register here
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
