import React from "react";

const Footer = () => {
  const navigateTo = (url) => {
    window.location.href = url;
  };

  return (
    <footer className="flex flex-col items-center justify-between p-6 bg-opacity-60 relative shadow-inner-lg mt-20 w-full text-center">
      <div className="flex flex-wrap justify-center items-center space-x-3 mb-4">
        <button
          onClick={() => navigateTo("/about")}
          className="px-4 py-2 h-10 rounded-full bg-secondary text-text shadow-md transition-transform transform hover:scale-105"
        >
          About
        </button>
        <button
          onClick={() => navigateTo("/")}
          className="px-4 py-2 h-10 rounded-full bg-secondary text-text shadow-md transition-transform transform hover:scale-105"
        >
          Home
        </button>
      </div>

      <p className="text-sm sm:text-base text-text mb-2">
        &copy; 2024 K-Anime | All rights reserved.
      </p>

      <div className="text-xs sm:text-sm text-text">
        <p>
          DISCLAIMER: This site does not store any files on its server. All
          contents are provided by non-affiliated third parties.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
