import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ProfileSettings = () => {
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [croppedProfilePicture, setCroppedProfilePicture] = useState(null);
  const [removeProfilePicture, setRemoveProfilePicture] = useState(false);
  const [newBackgroundColor, setNewBackgroundColor] = useState('#ff007c');
  const [newBackgroundPicture, setNewBackgroundPicture] = useState(null);
  const [croppedBackgroundPicture, setCroppedBackgroundPicture] = useState(null);
  const [removeBackgroundPicture, setRemoveBackgroundPicture] = useState(false);
  const [newBio, setNewBio] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isPremium, setIsPremium] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [backgroundModalVisible, setBackgroundModalVisible] = useState(false);
  const [colorModalVisible, setColorModalVisible] = useState(false);
  const profileCropperRef = useRef(null);
  const backgroundCropperRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('/api/users/me', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data.');
        }

        const data = await response.json();
        setIsPremium(data.premium || false);
        setNewBackgroundColor(data.bgColor || '#ff007c');
        setNewBio(data.bio || '');
      } catch (err) {
        setError(err.message);
      }
    };

    fetchUserData();
  }, []);

  const handleProfileSettingsChange = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (croppedProfilePicture) {
      formData.append('pfp', croppedProfilePicture);
    }
    if (croppedBackgroundPicture && isPremium) {
      formData.append('bg', croppedBackgroundPicture);
    }
    if (removeProfilePicture) {
      formData.append('removePfp', 'true');
    }
    if (removeBackgroundPicture) {
      formData.append('removeBg', 'true');
    }
    formData.append('bgColor', newBackgroundColor);
    formData.append('bio', newBio);

    try {
      const response = await fetch('/api/users/update', {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update profile settings.');
      }

      const updatedUser = await response.json();
      setSuccess('Profile settings updated successfully.');

      setNewBackgroundColor(updatedUser.bgColor || newBackgroundColor);
      setNewBio(updatedUser.bio || newBio);

      setRemoveProfilePicture(false);
      setRemoveBackgroundPicture(false);

      localStorage.setItem('profileUpdated', Date.now().toString());
    } catch (err) {
      setError(err.message);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (e.target.name === 'pfp') {
      setNewProfilePicture(URL.createObjectURL(file));
      setRemoveProfilePicture(false);
      setProfileModalVisible(true);
    } else if (e.target.name === 'bg') {
      setNewBackgroundPicture(URL.createObjectURL(file));
      setRemoveBackgroundPicture(false);
      setBackgroundModalVisible(true);
    }
  };

  const cropProfilePicture = () => {
    const cropper = profileCropperRef.current?.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      setCroppedProfilePicture(blob);
      setProfileModalVisible(false);
    });
  };

  const cropBackgroundPicture = () => {
    const cropper = backgroundCropperRef.current?.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      setCroppedBackgroundPicture(blob);
      setBackgroundModalVisible(false);
    });
  };

  const handleRemoveProfilePicture = () => {
    setNewProfilePicture(null);
    setRemoveProfilePicture(true);
  };

  const handleRemoveBackgroundPicture = () => {
    setNewBackgroundPicture(null);
    setRemoveBackgroundPicture(true);
    setNewBackgroundColor('#ff007c');
  };

  const debouncedColorChange = debounce((color) => {
    setNewBackgroundPicture(null);
    setNewBackgroundColor(color.hex);
  }, 100);

  return (
    <div className="w-full md:w-3/4 p-6 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold mb-6 text-center">Profile Settings</h2>

      {/* Error and Success Messages */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
          {error}
        </div>
      )}
      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded mb-4">
          {success}
        </div>
      )}

      <form onSubmit={handleProfileSettingsChange}>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Profile Picture</h3>
          <div className="flex items-center space-x-3">
            <input
              type="file"
              name="pfp"
              onChange={handleFileChange}
              className="border p-2 rounded w-full text-gray-700"
            />
            <button
              type="button"
              onClick={handleRemoveProfilePicture}
              className="px-3 py-1 rounded bg-red-500 text-white transition-transform transform hover:scale-105"
            >
              Remove
            </button>
          </div>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Background Color</h3>
          <button
            type="button"
            onClick={() => setColorModalVisible(true)}
            className="px-3 py-1 rounded bg-gray-300 text-black"
            style={{ backgroundColor: newBackgroundColor }}
          >
            Change Background Color
          </button>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Background Picture</h3>
          <div className="flex items-center space-x-3">
            <input
              type="file"
              name="bg"
              onChange={handleFileChange}
              className="border p-2 rounded w-full text-gray-700"
              disabled={!isPremium}
            />
            <button
              type="button"
              onClick={handleRemoveBackgroundPicture}
              className="px-3 py-1 rounded bg-red-500 text-white transition-transform transform hover:scale-105"
              disabled={!isPremium}
            >
              Remove
            </button>
          </div>
          {!isPremium && (
            <p className="text-background mt-2 text-xs">
              *Background image upload is a premium feature.
            </p>
          )}
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Bio</h3>
          <textarea
            value={newBio}
            onChange={(e) => setNewBio(e.target.value)}
            placeholder="Enter new bio"
            className="border p-2 rounded w-full text-gray-700"
            rows="4"
          />
        </div>

        <button
          type="submit"
          className="px-3 py-1 rounded bg-secondary text-text transition-transform transform hover:scale-105"
        >
          Update Profile
        </button>
      </form>

      {/* Profile Modal for Cropping */}
      {profileModalVisible && (
        <div className="fixed inset-0 z-50 flex items-center text-text justify-center bg-black bg-opacity-50">
          <div className="bg-secondary p-4 rounded-lg">
            <h4>Crop Profile Picture</h4>
            <Cropper
              src={newProfilePicture}
              style={{ height: 350, width: '100%' }}
              aspectRatio={1}
              viewMode={1}
              guides={false}
              ref={profileCropperRef}
            />
            <button
              type="button"
              onClick={cropProfilePicture}
              className="mt-4 px-3 py-1 rounded bg-accent text-text"
            >
              Crop and Save
            </button>
          </div>
        </div>
      )}

      {/* Background Modal for Cropping */}
      {backgroundModalVisible && (
        <div className="fixed inset-0 z-50 flex items-center text-text justify-center bg-black bg-opacity-50">
          <div className="bg-secondary p-4 rounded-lg">
            <h4>Crop Background Picture</h4>
            <Cropper
              src={newBackgroundPicture}
              style={{ height: 300, width: '100%' }}
              aspectRatio={16 / 9}
              viewMode={1}
              guides={false}
              ref={backgroundCropperRef}
            />
            <button
              type="button"
              onClick={cropBackgroundPicture}
              className="mt-4 px-3 py-1 rounded bg-accent text-text"
            >
              Crop and Save
            </button>
          </div>
        </div>
      )}

      {/* Color Picker Modal */}
      {colorModalVisible && (
        <div className="fixed inset-0 z-50 flex items-center text-text justify-center bg-black bg-opacity-50">
          <div className="bg-secondary p-4 rounded-lg">
            <h4>Select Background Color</h4>
            <ChromePicker color={newBackgroundColor} onChange={debouncedColorChange} />
            <button
              type="button"
              onClick={() => setColorModalVisible(false)}
              className="mt-4 px-3 py-1 rounded bg-accent text-text"
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

export default ProfileSettings;
