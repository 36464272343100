import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

const WatchlistButton = ({ animeId }) => {
  const { token, user } = useAuth();
  const [inWatchlist, setInWatchlist] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchWatchlistStatus = async () => {
      try {
        const response = await fetch(`/api/users/${user.username}/watchlist`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch watchlist: ${response.statusText}`);
        }

        const data = await response.json();
        const isInWatchlist = data.watchlist.some(item => item.anime === animeId);
        setInWatchlist(isInWatchlist);
      } catch (error) {
        console.error('Error fetching watchlist:', error);
      }
    };

    if (user && token) {
      fetchWatchlistStatus();
    }
  }, [animeId, token, user]);

  const handleAddToWatchlist = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/users/${user.username}/watchlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ animeId }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to add to watchlist: ${response.statusText}`);
      }
  
      setInWatchlist(true);
    } catch (error) {
      console.error('Error adding to watchlist:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleRemoveFromWatchlist = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/users/${user.username}/watchlist/${animeId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to remove from watchlist: ${response.statusText}`);
      }
  
      setInWatchlist(false);
    } catch (error) {
      console.error('Error removing from watchlist:', error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      {inWatchlist ? (
        <button
          className="px-4 py-2 bg-primary text-text rounded-lg hover:scale-105 transition-transform duration-200"
          onClick={handleRemoveFromWatchlist}
          disabled={loading}
        >
          {loading ? 'Removing...' : 'Remove from Watchlist'}
        </button>
      ) : (
        <button
          className="px-4 py-2 bg-primary text-text rounded-lg hover:scale-105 transition-transform duration-200"
          onClick={handleAddToWatchlist}
          disabled={loading}
        >
          {loading ? 'Adding...' : 'Add to Watchlist'}
        </button>
      )}
    </div>
  );
};

export default WatchlistButton;
