import React, { useState } from 'react';
import SettingsSidebar from '../components/SettingsSidebar';
import SettingsContent from '../components/SettingsContent';

const SettingsPage = () => {
  const [selectedCategory, setSelectedCategory] = useState('account');

  return (
    <div className="flex flex-col md:flex-row w-full h-full">
      <SettingsSidebar
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
      />
      <SettingsContent selectedCategory={selectedCategory} />
    </div>
  );
};

export default SettingsPage;
