import React, { useState, useEffect } from 'react';
import standardpfp from '../images/standardpfp.jpeg';
import { Link } from 'react-router-dom';
import decorations from '../components/decoration';

const ListProfile = ({ username, rank, type }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`/api/users/${username}`);
        if (!response.ok) {
          throw new Error(`User not found: ${response.status}`);
        }
        const data = await response.json();
        setUserData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [username]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!userData || !userData.leaderboardVisibility) return null;

  const {
    pfp,
    xp,
    xpWeekly,
    xpMonthly,
    currentDecoration,
    leaderboardNameVisibility,
  } = userData;

  const displayName = leaderboardNameVisibility ? username : 'Anonymous';
  const profilePicture = pfp || standardpfp;
  const decorationArray = Array.isArray(decorations) ? decorations : Object.values(decorations);
  const decoration = decorationArray.find(deco => deco.key === currentDecoration);
  const displayedXP = type === 'weekly' ? xpWeekly : type === 'monthly' ? xpMonthly : xp;

  return (
    <div className="flex justify-between items-center py-3 bg-secondary rounded-lg mb-3 p-4">
      <div className="flex items-center space-x-2">
        <div className="relative flex justify-center items-center">
          <div className="relative p-2">
            {decoration && (
              <img
                src={decoration.image}
                alt={`${currentDecoration} decoration`}
                className="absolute z-20"
                style={{
                  width: '100%', 
                  height: '100%',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)', 
                }}
              />
            )}
            <img
              src={profilePicture}
              alt={username}
              className="w-12 h-12 object-cover rounded-full z-10"
            />
          </div>
        </div>
        <div>
          <div className="text-md text-text font-bold">
            <Link to={`/profile/${username}`} className="hover:underline">
              {displayName}
            </Link>
          </div>
          <p className="text-sm text-text">XP: {displayedXP}</p>
        </div>
      </div>
      <div className="text-right">
        <p className="text-lg font-bold text-text">Rank: {rank}</p>
      </div>
    </div>
  );
};  
export default ListProfile;
