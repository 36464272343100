import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { ThemeProvider } from "./contexts/ThemeContext";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import SearchPage from "./pages/SearchPage";
import HomePage from "./pages/HomePage";
import Profile from "./pages/ProfilePage";
import SettingsPage from "./pages/SettingsPage";
import Shop from "./pages/ShopPage";
import Leaderboard from "./pages/Leaderboard";
import About from './pages/About';
import UserProfile from "./pages/UserProfile";
import AnimePage from "./pages/Details";
import WatchingPage from "./pages/Watching";
import ReferralRoadmap from "./pages/Referrals";
import './index.css';
import ForgotPassword from "./pages/ForgotPassword";
import DevLog from "./pages/News";
import DonationPage from "./pages/Donate";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path:"/register",
        element: <RegisterPage />,
      },{
        path: "/search",
        element: <SearchPage />,
      },
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "/me",
        element: <Profile />,
      },
      {
        path: "/settings",
        element: <SettingsPage />,
      },
      
      {
        path: "/shop",
        element: <Shop />,
      },
      {
        path: "/leaderboard",
        element: <Leaderboard />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/profile/:username",
        element: <UserProfile />,
      },
      {
        path: "/anime/:id",
        element: <AnimePage />,
      },
      {  
        path:"/anime/:animeId/:episodeTitle",
        element: <WatchingPage />},
      {
        path:"/referral",
        element: <ReferralRoadmap/>
      },
      {
        path:"/forgot-password",
        element: <ForgotPassword/>
      },
      {
        path:"/news",
        element: <DevLog/>
      },
      {
        path:"/donate",
        element: <DonationPage/>
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
