import React, { useState } from 'react';

const DevLog = () => {
  const [devLogs] = useState([
    {
      title: 'Version 1.0.0 - Initial Release',
      date: 'September 22nd, 2024',
      content: `TL;DR: Launched with core features including:
      - Anime browsing
      - Watchlists
      - User profiles
      - Leaderboard
      - Shop
      - XP system
      - Referrals
      - Random anime
      - Settings
      - Decorations & Themes
      - 2FA for password reset
      
      Disclaimer: This project is automated. The developers will most likely continue working on the project.
      To support the project we enabled ads. In case of bugs, post about it online. We will most likely see. 
      We are also aware that there might be episodes missing. We are working on that.
      
      In-depth features list:
      - Anime browsing by name, genre
      - Homepage categories: Recently added episodes/anime, watchlist, suggestions, comedy, action, adventure, romance
      - Light and dark mode
      - Leaderboard for monthly, weekly and overall xp gainers
      - Random anime selector
      - Anime details page with summary, total episodes, aliases, types, name, add to watchlist function, episodes
      - Episodes, previous and next episode buttons, episodes listed below the player, xp/min watched of anime caps at max min of anime
      - User profile pic and background, premium, bio, followers, following, social media, unlocked themes and decorations
      - Shop items: premium, xp boosts, decorations, themes
      - Referrals with code copy, rewards
      - Settings: 
                 *Account settings: username change, email change, password change, 2FA enable/disable, delete account
                 *Profile settings: pfp, background color, background image, cropping images function, bio
                 *Privacy settings: leaderboard visibility on/off
                 *Theme settings: selecting themes
                 *Decoration settings: selecting pfp decorations
                 *Social media settings: adding social media, will be displayed on profile
        - About explains more about the project and future plans.`,

    },
  ]);

  return (
    <div className="w-full p-5 md:p-12">
      <h1 className="text-4xl font-bold mb-3" style={{ color: 'var(--text-color)' }}>
        Dev Log
      </h1>
      <div className="space-y-8">
        {devLogs.map((log, index) => (
          <div key={index} className="p-6 shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold mb-2" style={{ color: 'var(--text-color)' }}>
              {log.title}
            </h2>
            <p className="text-sm text-gray-500 mb-4">{log.date}</p>
            <p className="text-lg" style={{ color: 'var(--text-color)', whiteSpace: 'pre-line' }}>
            {log.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevLog;
