import React, { useState, useEffect } from 'react';
import GenreSelector from '../components/GenreSelect';
import AnimeList from '../components/Searchlist';
import { debounce } from 'lodash';

const SearchPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [genre, setGenre] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [animes, setAnimes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const fetchAnimes = async (isNewSearch = false) => {
            try {
                setLoading(true);
                setError(null);
                const response = await fetch(`/api/anime/all?search=${searchQuery}&genre=${genre}&sortOrder=${sortOrder}&page=${page}&limit=50`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const fetchedAnimes = await response.json();
                if (fetchedAnimes.length < 50) {
                    setHasMore(false);
                }
                if (isNewSearch) {
                    setAnimes(fetchedAnimes);
                } else {
                    setAnimes(prevAnimes => [...prevAnimes, ...fetchedAnimes]);
                }
            } catch (error) {
                setError('Failed to fetch animes. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        const debouncedFetchAnimes = debounce(() => fetchAnimes(true), 300);
        debouncedFetchAnimes();
        return () => {
            debouncedFetchAnimes.cancel();
        };
    }, [searchQuery, genre, sortOrder, page]);

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
        setAnimes([]);
        setPage(1);
        setHasMore(true);
    };

    const handleGenreChange = (newGenre) => {
        setGenre(newGenre);
        setAnimes([]);
        setPage(1);
        setHasMore(true);
    };

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    return (
        <div className="w-full max-w-screen-2xl mx-auto p-4">
            <div className="mb-8">
                <h2 className="text-4xl font-bold text-center text-text mb-6">Search Anime</h2>
                <form className="flex flex-col md:flex-row gap-4" onSubmit={(e) => e.preventDefault()}>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                        className="w-full bg-secondary text-text md:w-1/3 px-4 py-2 border border-primary rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="Search by name or alias"
                    />
                    <GenreSelector genre={genre} setGenre={handleGenreChange} />
                    <select
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                        className="w-full bg-secondary text-text md:w-1/4 px-4 py-2 border border-primary rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </form>
            </div>

            {loading && animes.length === 0 ? (
                <div className="text-center">Loading...</div>
            ) : error ? (
                <div className="text-center text-red-500">{error}</div>
            ) : animes.length === 0 ? (
                <div className="text-center">No anime found</div>
            ) : (
                <>
                    <AnimeList animes={animes} />
                    {hasMore && (
                        <div className="text-center mt-4">
                            <button
                                onClick={loadMore}
                                className="px-6 py-3 bg-accent text-text rounded-lg font-semibold shadow-md hover:scale-105 hover:bg-accent focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Load More'}
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SearchPage;
