import React from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import { useTheme } from "../contexts/ThemeContext";

const ThemeToggle = () => {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <div className="flex items-center justify-center">
      <div
        className={`relative w-14 h-8 flex items-center rounded-full shadow-lg p-1 cursor-pointer transition-colors duration-300 ${
          isDarkMode ? "bg-primary" : "bg-primary"
        }`}
        onClick={toggleDarkMode}
      >
        <div
          className={`absolute w-6 h-6 bg-white rounded-full shadow-lg transform transition-transform duration-300 ${
            isDarkMode ? "translate-x-6" : "translate-x-0"
          }`}
        >
          <div className="flex items-center justify-center h-full">
            {isDarkMode ? (
              <FaMoon className="text-primary w-3 h-3" />
            ) : (
              <FaSun className="text-primary w-3 h-3" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeToggle;
