import React from "react";

const Button = ({ text, isPrimary, action, height, width }) => {
  const buttonClasses = `px-6 py-2 rounded-full transition-all transform focus:outline-none focus:ring-2 focus:ring-offset-2 ${
    isPrimary
      ? "bg-primary text-text hover:bg-primary"
      : "bg-accent text-text hover:bg-primary"
  } shadow-lg hover:scale-105 focus:scale-105`;

  return (
    <button
      onClick={action}
      className={buttonClasses}
      style={{
        height: height ? `${height}px` : "auto",
        width: width ? `${width}px` : "auto", 
        minHeight: "40px", 
        padding: height ? "0 20px" : "10px 20px", 
      }}
      aria-label={text}
      tabIndex="0"
    >
      {text}
    </button>
  );
};

export default Button;
