import React, { useEffect, useState } from 'react';
import standardpfp from '../images/standardpfp.jpeg';
import { Link } from 'react-router-dom';

const FollowersList = ({ listType, username, onClose }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchList = async () => {
      let endpoint = listType === 'followers'
        ? `/api/users/${username}/followers`
        : `/api/users/${username}/following`;

      try {
        const response = await fetch(endpoint);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${listType} list`);
        }
        const data = await response.json();
        setList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchList();
  }, [listType, username]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
      ></div>

      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-primary text-text rounded-lg p-4 w-80 max-h-[70vh] overflow-y-auto shadow-lg">
          <div className="flex justify-between items-center mb-3">
            <h2 className="text-xl font-bold text-text">
              {listType.charAt(0).toUpperCase() + listType.slice(1)}
            </h2>
            <button className="text-red-500" onClick={onClose}>
              Close
            </button>
          </div>
          {list.length > 0 ? (
            <ul className="space-y-3">
              {list.map((user) => (
                <li key={user._id} className="flex items-center space-x-3">
                  <img
                    src={user.pfp || standardpfp}
                    alt="profile"
                    className="w-8 h-8 rounded-full"
                  />
                  <Link to={`/profile/${user.username}`} className="hover:underline">
                    {user.username}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-text">No {listType} found.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default FollowersList;
