import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const PrivacySettings = () => {
  const { token } = useAuth();
  const [privacySettings, setPrivacySettings] = useState({
    leaderboardVisibility: true,
  });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchPrivacySettings = async () => {
      try {
        const response = await fetch('/api/users/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch privacy settings');
        }

        const data = await response.json();
        setPrivacySettings({
          leaderboardVisibility: data.leaderboardVisibility,
        });
      } catch (err) {
        setError('Error fetching privacy settings');
      }
    };

    fetchPrivacySettings();
  }, [token]);

  const handleToggleChange = (setting) => {
    setPrivacySettings((prevSettings) => ({
      ...prevSettings,
      [setting]: !prevSettings[setting],
    }));
  };

  const handleSaveSettings = async () => {
    try {
      const response = await fetch('/api/users/privacy-settings', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(privacySettings),
      });

      if (!response.ok) {
        throw new Error('Failed to save privacy settings');
      }

      setSuccessMessage('Privacy settings updated successfully!');
    } catch (err) {
      setError('Error updating privacy settings');
    }
  };

  return (
    <div className="w-full md:w-3/4 p-6 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold mb-6 text-center">Privacy Settings</h2>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
          {error}
        </div>
      )}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded mb-4">
          {successMessage}
        </div>
      )}

      <div className="flex flex-col space-y-4">
        {Object.keys(privacySettings).map((setting) => (
          <div key={setting} className="flex justify-between items-center">
            <label
              htmlFor={setting}
              className="font-semibold capitalize text-lg"
            >
              {setting.replace(/([A-Z])/g, ' $1').replace('Visibility', ' visibility')}
            </label>
            <button
              onClick={() => handleToggleChange(setting)}
              className={`px-4 py-2 rounded text-white transition-transform transform hover:scale-105 ${
                privacySettings[setting] ? 'bg-green-500' : 'bg-gray-500'
              }`}
            >
              {privacySettings[setting] ? 'On' : 'Off'}
            </button>
          </div>
        ))}
        <button
          onClick={handleSaveSettings}
          className="w-full px-4 py-2 bg-secondary text-text rounded-lg font-semibold transition-transform transform hover:scale-105"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default PrivacySettings;
