import React, { useState, useEffect } from 'react';
import MiniProfile from './Miniprofile';
import ListProfile from './ListProfile';

const LeaderboardTop10 = ({ type }) => {
  const [top3Users, setTop3Users] = useState([]);
  const [top4to10Users, setTop4to10Users] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTopUsers = async () => {
      let endpoint = '';

      switch (type) {
        case 'weekly':
          endpoint = '/api/users/leaderboard/weekly';
          break;
        case 'monthly':
          endpoint = '/api/users/leaderboard/monthly';
          break;
        case 'overall':
          endpoint = '/api/users/leaderboard/overall';
          break;
        default:
          return;
      }

      try {
        const response = await fetch(endpoint, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const filteredUsers = data.filter(user => user.username); 
        setTop3Users(filteredUsers.slice(0, 3));
        setTop4to10Users(filteredUsers.slice(3, 10));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTopUsers();
    const intervalId = setInterval(fetchTopUsers, 5000); 

    return () => clearInterval(intervalId);
  }, [type]);

  if (loading) return <div className="text-white">Loading...</div>;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  return (
    <div className="mt-4">
      <div className="flex justify-center space-x-4 mt-10">
        {top3Users.map((user, index) => (
          <MiniProfile 
            key={user._id}
            username={user.username}
            type={type}  
            rank={index + 1}
          />
        ))}
      </div>
      <div className="mt-4">
        {top4to10Users.map((user, index) => (
          <ListProfile 
            key={user._id}
            rank={index + 4}  
            username={user.username}
            type={type}  
          />
        ))}
      </div>
    </div>
  );
};

export default LeaderboardTop10;
