import React from "react";
import Footer from "./components/Footer";
import TopBar from "./components/TopBar";
import Background from "./components/Background";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { ParallaxProvider } from 'react-scroll-parallax';

function App() { 
  return (
    <>
      <AuthProvider>
        <ThemeProvider>
        <ParallaxProvider>
          <Background>
            <TopBar />
            <Outlet />
            <Footer />
          </Background>
          </ParallaxProvider>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}

export default App;
