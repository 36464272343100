import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Parallax } from 'react-scroll-parallax';  
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../contexts/AuthContext";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { fetchRecentlyAddedAnime } from '../components/RecentlyAdded';
import { fetchForYouAnime } from '../components/ForYou';
import { fetchAnimeByGenre } from '../components/FetchByGenre';
import heroImage from '../images/highres.png'; 

const HomePage = () => {
  const { user, token } = useAuth();
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const scrollRefs = useRef({});
  const [scrollPositions, setScrollPositions] = useState({});
  const [recentlyAdded, setRecentlyAdded] = useState([]);
  const [forYouAnime, setForYouAnime] = useState([]);
  const [comedyAnime, setComedyAnime] = useState([]);
  const [actionAnime, setActionAnime] = useState([]);
  const [cultivationAnime, setCultivationAnime] = useState([]);
  const [romanceAnime, setRomanceAnime] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const [recentEpisodes, setRecentEpisodes] = useState([]);

  const navigate = useNavigate();

  const handleNewsClick = () => {
    navigate('/news');
  };

  const fetchRecentEpisodes = async () => {
    try {
      const response = await fetch('/api/anime/recent-episodes?limit=20');
      if (!response.ok) {
        throw new Error('Failed to fetch recent episodes');
      }
      const data = await response.json();
      return data.results;
    } catch (error) {
      console.error('Error fetching recent episodes:', error);
      return [];
    }
  };

  const sanitizeTitleForURL = (title) => {
    return title.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase(); 
  };

  const scroll = useCallback((genre, direction) => {
    if (scrollRefs.current[genre]) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      scrollRefs.current[genre].scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
      setScrollPositions((prev) => ({
        ...prev,
        [genre]: (prev[genre] || 0) + scrollAmount,
      }));
    }
  }, []);

  const handleScroll = useCallback((genre) => {
    const scrollLeft = scrollRefs.current[genre].scrollLeft;
    const scrollWidth = scrollRefs.current[genre].scrollWidth;
    const clientWidth = scrollRefs.current[genre].clientWidth;

    setScrollPositions((prev) => ({
      ...prev,
      [genre]: scrollLeft,
      [`${genre}_max`]: scrollWidth - clientWidth,
    }));
  }, []);

  useEffect(() => {
    const hasTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(hasTouchScreen);

    fetchRecentlyAddedAnime().then(setRecentlyAdded);
    fetchForYouAnime().then(setForYouAnime);
    fetchAnimeByGenre('Comedy').then(setComedyAnime);
    fetchAnimeByGenre('Action').then(setActionAnime);
    fetchAnimeByGenre('Adventure').then(setCultivationAnime);
    fetchAnimeByGenre('Romance').then(setRomanceAnime);

    if (user && token) {
      const fetchWatchlist = async () => {
        try {
          const response = await fetch(`/api/users/${user.username}/watchlist`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch watchlist');
          }

          const data = await response.json();
          setWatchlist(data.watchlist);
        } catch (error) {
          console.error('Error fetching watchlist:', error);
        }
      };

      fetchWatchlist();
    }

    fetchRecentEpisodes().then(setRecentEpisodes);
  }, [user, token]);

  useEffect(() => {
    Object.keys(scrollRefs.current).forEach((genre) => {
      if (scrollRefs.current[genre]) {
        handleScroll(genre);
      }
    });
  }, [recentlyAdded, forYouAnime, comedyAnime, actionAnime, cultivationAnime, romanceAnime, watchlist, handleScroll]);

  const handleEpisodeClick = (animeId, episode) => {
    const sanitizedTitle = sanitizeTitleForURL(episode.episodeTitle);
    navigate(`/anime/${animeId}/${sanitizedTitle}`);
  };

  const handleAnimeClick = (id) => {
    navigate(`/anime/${id}`);
  };

  const animes = {
    "Recently Added Anime": recentlyAdded.map((anime) => ({
      id: anime.id,
      title: anime.name,
      imageUrl: anime.image,
    })),
    ...(user && token && { 
      "Watchlist": watchlist.map((anime) => ({
        id: anime.anime.id,
        title: anime.anime.name,
        imageUrl: anime.anime.image,
      })),
    }),
    "Suggested For You": forYouAnime.map((anime) => ({
      id: anime.id,
      title: anime.name,
      imageUrl: anime.image,
    })),
    "Comedy": comedyAnime.map((anime) => ({
      id: anime.id,
      title: anime.name,
      imageUrl: anime.image,
    })),
    "Action": actionAnime.map((anime) => ({
      id: anime.id,
      title: anime.name,
      imageUrl: anime.image,
    })),
    "Adventure": cultivationAnime.map((anime) => ({
      id: anime.id,
      title: anime.name,
      imageUrl: anime.image,
    })),
    "Romance": romanceAnime.map((anime) => ({
      id: anime.id,
      title: anime.name,
      imageUrl: anime.image,
    })),
  };

  return (
    <div className="flex flex-col items-center text-text w-full">
      <div className="relative w-full h-[85vh] flex flex-col md:flex-row items-center justify-between p-6 md:p-10 bg-black bg-opacity-50 overflow-hidden box-border">
        <Parallax speed={-10}>
          <div className="w-full md:w-1/2 flex flex-col justify-right mb-8 md:mb-0">
            <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 whitespace-nowrap" style={{ color: 'var(--text-color)' }}>
              Watch anime and enjoy
            </h1>
            <p className="text-sm md:text-base mb-4 md:mb-6 whitespace-nowrap" style={{ color: 'var(--text-color)' }}>
              Don't forget the dev updates in <span className="cursor-pointer text-accent underline" onClick={handleNewsClick}>news!</span>
            </p>
          </div>
        </Parallax>

        <div className="w-full md:w-1/2 h-[40vh] md:h-full mb-6 md:mb-28 relative overflow-hidden">
          <Parallax speed={-10}>
            <img
              src={heroImage}
              alt="Hero"
              className="w-full h-full object-cover rounded-lg"
            />
          </Parallax>
        </div>
      </div>

      <div className="w-full mt-8 pr-4 pl-4">
        {recentEpisodes.length > 0 && (
          <div className="mb-6 p-2 rounded-lg relative" style={{ backgroundColor: 'var(--primary-color)' }}>
            <h2 className="text-xl font-bold mb-3" style={{ color: 'var(--text-color)' }}>
              Recent Episodes
            </h2>
            <div className="relative group">
              <div className="flex overflow-x-auto space-x-4 pb-3 pt-1 hide-scrollbar w-full">
                {recentEpisodes.map((episode, index) => (
                  <div
                    key={index}
                    className="w-[150px] h-[240px] flex-shrink-0 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105 hover:z-10 cursor-pointer"
                    style={{ flex: '0 0 150px', backgroundColor: 'var(--secondary-color)' }}
                    onClick={() => handleEpisodeClick(episode.animeId, episode)}
                  >
                    <img
                      src={episode.animeImage}
                      alt={episode.animeName}
                      className="w-full h-[200px] object-cover rounded-t-lg"
                    />
                    <div className="p-1">
                      <h3 className="text-xs font-semibold truncate max-w-full whitespace-nowrap overflow-hidden" style={{ color: 'var(--text-color)' }}>
                        {episode.animeName}
                      </h3>
                    </div>
                    <div className="flex justify-center items-center">
                      <h3
                        className="text-xs font-semibold text-center truncate max-w-full whitespace-nowrap overflow-hidden"
                        style={{ color: 'var(--text-color)' }}
                      >
                        Ep {episode.episodeNumber}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="w-full">
          {Object.keys(animes).map((genre) => (
            <div key={genre} className="mb-6 p-2 rounded-lg relative" style={{ backgroundColor: 'var(--primary-color)' }}>
              <h2 className="text-xl font-bold mb-3" style={{ color: 'var(--text-color)' }}>
                {genre.charAt(0).toUpperCase() + genre.slice(1)}
              </h2>
              <div className="relative group">
                <div
                  ref={(el) => (scrollRefs.current[genre] = el)}
                  className="flex overflow-x-auto space-x-4 pb-3 pt-1 hide-scrollbar w-full"
                  onScroll={() => handleScroll(genre)}
                >
                  {animes[genre].map((anime, index) => (
                    <div
                      key={index}
                      className="w-[150px] h-[240px] flex-shrink-0 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105 hover:z-10 cursor-pointer"
                      style={{ flex: '0 0 150px', backgroundColor: 'var(--secondary-color)' }}
                      onClick={() => handleAnimeClick(anime.id)}
                    >
                      <img
                        src={anime.imageUrl}
                        alt={anime.title}
                        className="w-full h-[200px] object-cover rounded-t-lg"
                      />
                      <div className="p-1">
                        <h3 className="text-xs font-semibold truncate max-w-full whitespace-nowrap overflow-hidden" style={{ color: 'var(--text-color)' }}>
                          {anime.title}
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
                {!isTouchDevice && (
                  <>
                    {scrollPositions[genre] > 0 && (
                      <button
                        className="absolute left-0 top-0 bottom-0 z-10 bg-opacity-30 bg-white rounded-md hover:bg-opacity-50 text-white p-2 flex items-center justify-center w-6 mb-auto mt-auto h-[50%]"
                        onClick={() => scroll(genre, 'left')}
                      >
                        <MdKeyboardArrowLeft size={24} />
                      </button>
                    )}
                    {scrollPositions[genre] < (scrollPositions[`${genre}_max`] || 0) && (
                      <button
                        className="absolute right-0 top-0 bottom-0 z-10 bg-opacity-30 bg-white rounded-md hover:bg-opacity-50 text-white p-2 flex items-center justify-center w-6 mb-auto mt-auto h-[50%]"
                        onClick={() => scroll(genre, 'right')}
                      >
                        <MdKeyboardArrowRight size={24} />
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
