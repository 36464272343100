import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import standardpfp from '../images/standardpfp.jpeg';
import FollowersList from '../components/FollowersList'; 
import decorations from '../components/decoration';

const Profile = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listType, setListType] = useState(null);

  const fetchUserData = async () => {
    try {
      const response = await fetch('/api/users/me', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setUser(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    fetchUserData();

    const handleStorageChange = (event) => {
      if (event.key === 'profileUpdated') {
        fetchUserData();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isLoggedIn, navigate]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching user data: {error.message}</p>;

  if (!user) return null;

  const {
    pfp,
    bg,
    bio,
    bgColor,
    xp,
    currentDecoration,
    premium,
    xpMonthly,
    xpWeekly,
    unlockedThemes,
    purchasedDecorations,
    socialMedia,
  } = user;

  const profilePic = pfp || standardpfp;
  const decorationArray = Array.isArray(decorations) ? decorations : Object.values(decorations);
  const decoration = decorationArray.find(deco => deco.key === currentDecoration);

  const isGif = bg && bg.endsWith('.gif');

  const socialMediaPlatforms = [
    { platform: 'discord', label: 'on Discord' },
    { platform: 'twitter', label: 'on Twitter' },
    { platform: 'instagram', label: 'on Instagram' },
    { platform: 'reddit', label: 'on Reddit' },
    { platform: 'spotify', label: 'on Spotify' },
    { platform: 'github', label: 'on GitHub' },
    { platform: 'paypal', label: 'on PayPal' },
    { platform: 'xbox', label: 'on Xbox' },
    { platform: 'playstation', label: 'on PlayStation' },
    { platform: 'tiktok', label: 'on TikTok' },
  ];

  const hasSocialMedia = socialMediaPlatforms.some(({ platform }) => socialMedia[platform]);

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6">
      <div className="relative rounded-xl overflow-hidden shadow-lg w-full h-40 sm:h-60 lg:h-72">
        {bg ? (
          isGif ? (
            <img
              src={bg}
              alt="profile background gif"
              className="w-full h-full object-cover"
            />
          ) : (
            <div
              className="w-full h-full"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          )
        ) : (
          <div
            className="w-full h-full"
            style={{
              backgroundColor: bgColor || '#ff007c',
            }}
          />
        )}
        
        {/* Profile and Decoration Container */}
        <div className="absolute inset-0 flex items-center justify-center">
          {/* Decoration */}
          {decoration && (
            <img
              src={decoration.image}
              alt={`${currentDecoration} decoration`}
              className="absolute z-20 w-24 h-24 sm:w-32 sm:h-32 lg:w-40 lg:h-40"
            />
          )}
          {/* Profile Picture */}
          <img
            src={profilePic}
            alt="profile"
            className="relative z-10 w-20 h-20 sm:w-28 sm:h-28 lg:w-32 lg:h-32 rounded-full"
          />
        </div>
      </div>

      <div className="w-full rounded-xl shadow-lg mt-8 sm:mt-12 p-4 sm:p-6 text-center">
        <h1 className="text-2xl sm:text-3xl font-bold text-text">{user.username}</h1>
        {premium && (
          <span className="text-sm font-semibold text-yellow-500">Premium User</span>
        )}
        <p className="mt-4 text-sm sm:text-base md:text-lg text-text">{bio || 'No bio available'}</p>

        <div className="flex justify-center mt-6 space-x-4">
          <button 
            className="bg-primary text-text px-4 py-2 rounded" 
            onClick={() => setListType('followers')}
          >
            Followers
          </button>
          <button 
            className="bg-primary text-text px-4 py-2 rounded" 
            onClick={() => setListType('following')}
          >
            Following
          </button>
        </div>

        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-center">
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-text">{xp} XP</h3>
            <p className="text-sm text-text">Total XP</p>
          </div>
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-text">{xpMonthly} XP</h3>
            <p className="text-sm text-text">Monthly XP</p>
          </div>
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-text">{xpWeekly} XP</h3>
            <p className="text-sm text-text">Weekly XP</p>
          </div>
        </div>

        {hasSocialMedia && (
          <div className="mt-8">
            <h2 className="text-xl sm:text-2xl text-text">Also known as...</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
              {socialMediaPlatforms.map(({ platform, label }) => (
                socialMedia[platform] && (
                  <div
                    key={platform}
                    className="flex flex-col items-center shadow-md rounded-lg p-4 hover:scale-105 transition duration-300 ease-in-out"
                  >
                    <span className="text-lg font-semibold text-text">{label}</span>
                    <p className="text-sm text-gray-500 mt-1">{socialMedia[platform]}</p>
                  </div>
                )
              ))}
            </div>
          </div>
        )}

        <div className="flex flex-wrap justify-between mt-8">
          <div className="w-full md:w-1/2 p-4">
            <h2 className="text-xl sm:text-2xl font-bold text-text">Unlocked Themes</h2>
            <div className="flex flex-wrap justify-center mt-4">
              {unlockedThemes.map((theme, index) => (
                <span key={index} className="mx-2 p-2 bg-primary rounded-md text-text">
                  {theme.charAt(0).toUpperCase() + theme.slice(1)}
                </span>
              ))}
            </div>
          </div>
          <div className="w-full md:w-1/2 p-4">
            <h2 className="text-xl sm:text-2xl font-bold text-text">Purchased Decorations</h2>
            <div className="flex flex-wrap justify-center mt-4">
              {purchasedDecorations.map((decoration, index) => (
                <span key={index} className="mx-2 p-2 bg-primary rounded-md text-text">
                  {decoration.charAt(0).toUpperCase() + decoration.slice(1)}
                </span>
              ))}
            </div>
          </div>
        </div>

        {listType && (
          <FollowersList 
            listType={listType} 
            username={user.username} 
            onClose={() => setListType(null)} 
          />
        )}
      </div>
    </div>
  );
};

export default Profile;
