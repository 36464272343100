import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const ShopItems = () => {
  const { user, token, isLoggedIn, refetchUserData } = useAuth();
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [purchaseErrors, setPurchaseErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    const fetchItems = async () => {
      try {
        const response = await fetch("/api/shop/shop", {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setItems(data);
      } catch (err) {
        setError("An unexpected error occurred. Please try again.");
      }
    };

    if (token) {
      fetchItems();
    }
  }, [token, isLoggedIn, navigate]);

  const handlePurchase = async (itemId) => {
    if (!token) {
      console.error('No auth token available during purchase');
      return;
    }

    try {
      const purchaseResponse = await fetch(`/api/shop/shop/purchase/${itemId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      if (!purchaseResponse.ok) {
        throw new Error('Failed to complete the purchase.');
      }

      await refetchUserData();
    } catch (err) {
      console.error("Error during purchase:", err);
      setPurchaseErrors(prev => ({ ...prev, [itemId]: err.message || "An unexpected error occurred. Please try again." }));
    }
  };

  const isItemPurchased = (item) => {
    if (!user) return false;

    if (item.category === "subscriptions") {
      const currentDate = new Date();
      return user.premiumSubscription && new Date(user.premiumSubscription) > currentDate;
    } else if (item.category === "themes") {
      return user.unlockedThemes.includes(item.theme);
    } else if (item.category === "profile-decorations") {
      return user.purchasedDecorations.includes(item.decoration);
    } else if (item.category === "xp-boosts") {
      const activeBoost = user.xpBoosts.some(boost => new Date(boost.expiresAt) > new Date());
      return activeBoost;
    }
    return false;
  };

  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="shop-items grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
      {items.length > 0 ? (
        items.map(item => {
          const imageUrl = `api/${item.image}`;
          const purchased = isItemPurchased(item);
          return (
            <div key={item._id} className="item-card p-4 border border-gray-200 rounded-lg shadow-md flex flex-col items-center justify-center">
              <img
                src={imageUrl}
                alt={item.name}
                className="w-36 h-36 object-cover rounded-md mb-4"
              />
              <h2 className="text-lg font-bold text-center">{item.name}</h2>
              <p className="text-md font-semibold mt-2 text-center">{item.price} XP</p>
              <button
                onClick={() => handlePurchase(item._id)}
                className={`mt-4 ${purchased ? 'bg-gray-500 cursor-not-allowed' : 'bg-secondary'} text-text py-1 px-3 rounded`}
                disabled={purchased}
              >
                {purchased ? 'Purchased' : 'Buy Now'}
              </button>
              {purchaseErrors[item._id] && <p className="text-red-500 mt-2">{purchaseErrors[item._id]}</p>}
            </div>
          );
        })
      ) : (
        <p>No items available</p>
      )}
    </div>
  );
};

export default ShopItems;
