import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  const fetchUserData = useCallback(async (authToken) => {
    if (!authToken) {
      console.error('No auth token provided for fetching user data');
      return;
    }

    try {
      const response = await fetch('/api/users/me', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch user data: ${response.status}`);
      }

      const data = await response.json();
      setUser(data);
      setIsLoggedIn(true);
    } catch (err) {
      console.error('Error fetching user data:', err);
      setIsLoggedIn(false);
      setToken(null);
      setUser(null);
      localStorage.removeItem('authToken'); 
    }
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      setToken(storedToken);
      fetchUserData(storedToken);
    } else {
      setIsLoggedIn(false);
      setToken(null);
      setUser(null);
    }
  }, [fetchUserData]);

  const refetchUserData = () => {
    if (token) {
      fetchUserData(token);  
    }
  };

  const login = (newToken) => {
    localStorage.setItem('authToken', newToken);
    setToken(newToken);
    setIsLoggedIn(true);
    fetchUserData(newToken);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setIsLoggedIn(false);
    setToken(null);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, token, user, login, logout, refetchUserData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
