import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ animeId, animeName, episodeTitle }) => {
  return (
    <nav className="bg-primary text-text w-full py-4">
      <div className="max-w-7xl mx-auto px-6">
        <ol className="list-reset flex flex-wrap text-sm md:text-base">
          <li className="inline-block">
            <Link to="/" className="text-text hover:text-accent">Home</Link>
          </li>
          <li className="inline-block">
            <span className="mx-2 text-text">/</span>
          </li>
          <li className="inline-block">
            <Link to={`/anime/${animeId}`} className="text-text hover:text-accent">
              {animeName}
            </Link>
          </li>
          <li className="inline-block">
            <span className="mx-2 text-text">/</span>
          </li>
          <li className="inline-block text-text">
            {episodeTitle}
          </li>
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumbs;
