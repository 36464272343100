import sakuraDecoration from '../images/sakuracircle.png';
import catsDecoration from '../images/catcircle.png';
import flowerDecoration from '../images/flowercircle.png';
import coquetteDecoration from '../images/coquettedecoration.png';
import bowDecoration from '../images/bowdecoration.png';
import leafDecoration from '../images/leafdecoration.png';
import jojoDecoration from '../images/jojodecoration.png';
import dragonDecoration from '../images/dragondecoration.png';
import koidecoration from '../images/koidecoration.png';

const decorations = [
  { name: 'Sakura', key: 'sakura', image: sakuraDecoration },
  { name: 'Cats', key: 'cats', image: catsDecoration },
  { name: 'Flowers', key: 'flowers', image: flowerDecoration },
  { name: 'Coquette', key: 'coquette', image: coquetteDecoration },
  { name: 'Bows', key: 'bows', image: bowDecoration },
  { name: 'Leaf', key: 'leaf', image: leafDecoration },
  { name: 'Menace', key: 'menace', image: jojoDecoration },
  { name: 'Dragon', key: 'dragon', image: dragonDecoration },
  { name: 'Koi - Referral Reward', key: 'special-decoration', image: koidecoration},
];

export default decorations;