import React from "react";

const Background = ({ children }) => {
  return (
    <>
      <div className="min-h-screen bg-gradient-to-tr from-primary to-secondary text-text relative flex-col">
        {children}
      </div>
    </>
  );
};

export default Background;
