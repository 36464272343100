import React from "react";

const Box = ({ children, width }) => {
  const widthClass = width ? `w-${width}` : "w-full max-w-4xl";
  const className = `${widthClass} mt-0 bg-secondary bg-opacity-60 rounded-lg shadow-lg p-8 backdrop-filter backdrop-blur-lg flex-col items-center justify-center`; // Changed from mt-10 to mt-0

  return (
    <main className="flex flex-col items-center justify-center p-6 relative">
      <div className={className}>{children}</div>
    </main>
  );
};

export default Box;
