import React, { useState, useEffect } from 'react';

const transformIdToName = (id) => {
  return id
    .replace(/-/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const AnimeNamecard = ({ animeId }) => {
  const [anime, setAnime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFullSummary, setShowFullSummary] = useState(false);

  useEffect(() => {
    const fetchAnimeDetails = async () => {
      try {
        const response = await fetch(`/api/anime/${animeId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch anime details');
        }
        const data = await response.json();
        setAnime(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (animeId) {
      fetchAnimeDetails();
    }
  }, [animeId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!anime) return null;

  const releaseYear = anime.released ? new Date(anime.released).getFullYear() : 'N/A';

  return (
    <div className="anime-namecard bg-primary p-4 rounded-md shadow-md flex items-start gap-4">
      <img
        src={anime.image || '/default-image.jpg'}
        alt={transformIdToName(animeId)}
        className="w-32 h-48 object-cover rounded-md"
      />

      <div className="anime-details flex-grow">
        <h3 className="text-3xl text-text font-semibold mb-2">{transformIdToName(animeId)}</h3>

        <p className="text-text text-sm italic mb-4">{anime.aliases?.join(', ') || ''}</p>

        <div className="anime-metadata text-text text-sm mb-4">
          <p><strong>Type:</strong> {anime.type}</p>
          <p><strong>Status:</strong> {anime.status}</p>
          <p><strong>Episodes:</strong> {anime.totalEpisodes || 'Unknown'}</p>
          <p><strong>Released Year:</strong> {releaseYear}</p>
          <p><strong>Genres:</strong> {anime.genres?.join(', ') || 'N/A'}</p>
        </div>

        <p className="text-text text-sm">
          {showFullSummary
            ? anime.summary
            : anime.summary.length > 100
              ? `${anime.summary.slice(0, 100)}... `
              : anime.summary}
          {anime.summary.length > 100 && (
            <span
              onClick={() => setShowFullSummary(!showFullSummary)}
              className="cursor-pointer text-accent hover:underline"
            >
              [{showFullSummary ? 'less' : 'more'}]
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default AnimeNamecard;
