import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const RelatedAnime = ({ animeId }) => {
  const [relatedAnime, setRelatedAnime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRelatedAnime = async () => {
      try {
        const response = await fetch(`/api/anime/${animeId}/related`);
        if (!response.ok) {
          throw new Error('Failed to fetch related anime');
        }
        const data = await response.json();
        setRelatedAnime(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (animeId) {
      fetchRelatedAnime();
    }
  }, [animeId]);

  if (loading) return <p>Loading related anime...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="related-anime-container">
      <h3 className="text-xl font-semibold mb-4">Related Anime</h3>
      <div className="space-y-4">
        {relatedAnime.map((anime) => (
          <Link
            to={`/anime/${anime.id}`}  
            key={anime._id}
            className="flex items-start gap-4 p-2 bg-primary rounded-md shadow-md hover:bg-secondary transition duration-300"
          >
            <img
              src={anime.image || '/default-image.jpg'}
              alt={anime.title}
              onError={(e) => { e.target.src = '/default-image.jpg'; }}
              className="w-32 h-48 object-cover rounded-md"  
            />
            <div className="flex-grow">
              <h4 className="text-lg font-semibold text-text mb-2">{anime.name || anime.title}</h4>
              <p className="text-text text-sm mb-2">
                <strong>Genres:</strong> {anime.genres?.join(', ') || 'N/A'}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RelatedAnime;
