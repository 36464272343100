import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import Button from "../components/Button";

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    referralCode: "",
    enable2FA: false,
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState(null); 
  const [twoFaKey, setTwoFaKey] = useState(null);  

  const navigate = useNavigate();

  const { username, email, password, confirmPassword, referralCode, enable2FA } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.id]: e.target.value });

  const onCheckboxChange = (e) => setFormData({ ...formData, [e.target.id]: e.target.checked });

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setError(null);
    setSuccess(null);

    try {
      const response = await fetch("/api/users/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, email, password, referralCode, enable2FA }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess("You have successfully registered!");

        if (enable2FA) {
          const twoFAResponse = await fetch(`/api/users/enable-2fa`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }), 
          });

          const twoFaData = await twoFAResponse.json();
          setQrCodeUrl(twoFaData.qrCodeUrl);  
          setTwoFaKey(twoFaData.secretKey);  
        } else {
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
      } else {
        setError(data.error || "An unexpected error occurred.");
      }
    } catch (err) {
      console.error("Error:", err);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full min-h-screen"> 
      <div className="w-full max-w-xs mx-auto p-6 bg-secondary bg-opacity-60 rounded-lg shadow-lg mb-12">
        <h2 className="text-xl font-bold text-text text-center mb-4">Create an Account</h2>
        {error && <p className="text-red-500 text-center text-sm">{error}</p>}
        {success && <p className="text-green-500 text-center text-sm">{success}</p>}

        <form onSubmit={onSubmit} className="space-y-3">
          <div>
            <label className="block text-text text-sm font-semibold mb-1" htmlFor="username">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={onChange}
              className="w-full px-3 py-2 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary text-black"
              placeholder="Enter your username"
            />
          </div>
          <div>
            <label className="block text-text text-sm font-semibold mb-1" htmlFor="email">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              className="w-full px-3 py-2 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary text-black"
              placeholder="Enter your email"
            />
          </div>
          <div>
            <label className="block text-text text-sm font-semibold mb-1" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={onChange}
              className="w-full px-3 py-2 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary text-black"
              placeholder="Create a password"
            />
          </div>
          <div>
            <label className="block text-text text-sm font-semibold mb-1" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={onChange}
              className="w-full px-3 py-2 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary text-black"
              placeholder="Confirm your password"
            />
          </div>
          <div>
            <label className="block text-text text-sm font-semibold mb-1" htmlFor="referralCode">
              Referral Code (Optional)
            </label>
            <input
              type="text"
              id="referralCode"
              value={referralCode}
              onChange={onChange}
              className="w-full px-3 py-2 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary text-black"
              placeholder="Enter referral code (if any)"
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="enable2FA"
              checked={enable2FA}
              onChange={onCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="enable2FA" className="text-text text-sm">
              Enable 2FA for added security (recommended)
            </label>
          </div>

          <p className="text-xs text-red-500 mt-2">
            Without enabling 2FA, you won’t be able to recover your password.
          </p>

          <Button text="Register" isPrimary={true} width="full" />
        </form>
      <p className="text-center text-xs text-text mt-8">
        Already have an account?{" "}
        <a href="/login" className="text-text hover:underline">
          Login here
        </a>
      </p>

        {qrCodeUrl && (
          <div className="mt-6">
            <h3 className="text-center text-lg font-semibold mb-4">Scan the QR Code to Enable 2FA</h3>
            <img src={qrCodeUrl} alt="QR code for 2FA setup" className="mx-auto" />
            <p className="text-center text-sm mt-2">
              Or manually enter this key into your Authenticator app: <strong>{twoFaKey}</strong>
            </p>
          </div>
        )}
      </div>

     
    </div>
  );
};

export default RegisterPage;
