import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const DonationPage = () => {
  const moneroAddress = "43EQ1iDQF9kNv8VNRH7CUYX2EJ3uMKsbgEVrSfzfbwWBERoRJ48KcS1YUJndhntkBWf3SJv97kbaLVR6qTXPZM3jNHbUfmb";
  const bitcoinAddress = "bc1qv3r2fyfyu2jhg3870h774ww5wmh83wck8edmjc";
  
  const maskAddress = (address) => {
    if (address.length <= 6) return address;
    return `${address.slice(0, 3)}...${address.slice(-3)}`;
  };
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen p-6">
     
      <div className="mb-1 text-center max-w-3xl">
        <h1 className="text-3xl font-bold text-text mb-4">Support Our Project</h1>
        <p className="text-lg text-text mb-6">
          If you enjoy using our service and would like to support its continued development, 
          consider donating via Monero or Bitcoin. Your generosity helps us cover costs and 
          maintain a free and open platform. Every contribution, no matter the size, is deeply appreciated.
        </p>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center w-full md:space-x-8">
       
        <div className="flex flex-col items-center justify-between m-4 p-6 rounded-lg bg-secondary w-full md:w-1/2">
          <h2 className="text-2xl font-bold text-text mb-4">Donate with Monero (XMR)</h2>

          <QRCodeCanvas value={moneroAddress} size={192} className="mb-4" /> {/* Render QR Code for Monero */}

          <p className="text-lg text-text text-center break-words mb-4">
            Address: <span className="text-accent">{maskAddress(moneroAddress)}</span>
          </p>
          <button
            onClick={() => navigator.clipboard.writeText(moneroAddress)}
            className="px-4 py-2 bg-primary text-text rounded-lg transition-transform transform hover:scale-105"
          >
            Copy Monero Address
          </button>
        </div>

        <div className="flex flex-col items-center justify-between m-4 p-6 rounded-lg bg-secondary w-full md:w-1/2">
          <h2 className="text-2xl font-bold text-text mb-4">Donate with Bitcoin (BTC)</h2>

          <QRCodeCanvas value={bitcoinAddress} size={192} className="mb-4" /> {/* Render QR Code for Bitcoin */}

          <p className="text-lg text-text text-center break-words mb-4">
            Address: <span className="text-accent">{maskAddress(bitcoinAddress)}</span>
          </p>
          <button
            onClick={() => navigator.clipboard.writeText(bitcoinAddress)}
            className="px-4 py-2 bg-primary text-text rounded-lg transition-transform transform hover:scale-105"
          >
            Copy Bitcoin Address
          </button>
        </div>
      </div>
    </div>
  );
};

export default DonationPage;