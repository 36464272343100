import React, { useState } from 'react';
import LeaderboardTop10 from '../leaderboard/Leaderboardtop10';

const Leaderboard = () => {
  const [activeTab, setActiveTab] = useState('weekly');

  return (
    <div className="min-h-screen flex flex-col items-center mt-5 pt-4 px-2"> 
      <div className="bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg w-full max-w-4xl"> 
    
        <div className="flex space-x-2 border-b border-primary overflow-x-auto hide-scrollbar"> 
          <button
            onClick={() => setActiveTab('weekly')}
            className={`text-sm px-4 py-2 font-semibold rounded-t-lg transition-colors ${
              activeTab === 'weekly' ? 'bg-primary text-text border-l border-t border-r border-primary' : 'bg-secondary text-text'
            }`}
          >
            Weekly
          </button>
          <button
            onClick={() => setActiveTab('monthly')}
            className={`text-sm px-4 py-2 font-semibold rounded-t-lg transition-colors ${
              activeTab === 'monthly' ? 'bg-primary text-text border-l border-t border-r border-primary' : 'bg-secondary text-text'
            }`}
          >
            Monthly
          </button>
          <button
            onClick={() => setActiveTab('overall')}
            className={`text-sm px-4 py-2 font-semibold rounded-t-lg transition-colors ${
              activeTab === 'overall' ? 'bg-primary text-text border-l border-t border-r border-primary' : 'bg-secondary text-text'
            }`}
          >
            Overall
          </button>
        </div>

        <div className="bg-primary bg-opacity-75 rounded-b-lg p-4"> 
          <LeaderboardTop10 type={activeTab} />
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
