import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import ShopItems from '../shop/ShopItems';
import Box from '../components/Box';
import { useNavigate } from 'react-router-dom';

const ShopPage = () => {
  const { isLoggedIn, token } = useAuth();
  const navigate = useNavigate();
  const [xp, setXp] = useState(0);

  const fetchUserData = useCallback(async () => {
    if (!token) {
      console.error('No auth token provided for fetching user data');
      return;
    }

    try {
      const response = await fetch('/api/users/me', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setXp(data.xp || 0);
    } catch (err) {
      console.error('An unexpected error occurred:', err);
    }
  }, [token]);

  useEffect(() => {
    if (!isLoggedIn || !token) {
      navigate('/login');
      return;
    }
    fetchUserData();
  }, [isLoggedIn, token, fetchUserData, navigate]);

  if (!isLoggedIn) {
    return <p>Loading...</p>;
  }

  return (
    <Box width="full">
      <div className="relative p-4">
        <div className="absolute top-0 right-0 p-4 text-lg bg-primary rounded-md shadow-md">
          XP: {xp}
        </div>
        <h1 className="text-3xl font-bold mb-6 text-center">Shop</h1>
        <div className="shop-page">
          <ShopItems />
        </div>
      </div>
    </Box>
  );
};

export default ShopPage;
