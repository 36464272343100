export const fetchAnimeByGenre = async (genre) => {
    try {
      const response = await fetch(`/api/anime/getgenre?genre=${genre}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch anime in the ${genre} genre`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };