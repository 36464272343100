import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const CustomPopup = ({ message, onConfirm, onCancel }) => (
  <div className="fixed inset-0 flex justify-center items-center bg-gray-600 bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
      <p className="text-gray-800">{message}</p> 
      <div className="mt-4">
        <button className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2" onClick={onConfirm}>
          Confirm
        </button>
        <button className="bg-gray-500 text-white px-4 py-2 rounded-lg" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  </div>
);


const AccountSettings = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState({ username: '', email: '', premium: false, is2FAEnabled: false });
  const [formData, setFormData] = useState({ newUsername: '', newEmail: '', newPassword: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [twoFaKey, setTwoFaKey] = useState(null);
  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      fetchUserData();
    }
  }, [isLoggedIn, navigate]);

  const fetchUserData = async () => {
    try {
      const response = await fetch('/api/users/me', {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setUser(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (endpoint, bodyData, successMessage) => {
    setError('');
    setSuccess('');
    try {
      const response = await fetch(`/api/users/${endpoint}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(bodyData),
      });
      if (!response.ok) throw new Error(`Failed to update ${endpoint}`);
      const updatedUser = await response.json();
      setUser(updatedUser);
      setSuccess(successMessage);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await fetch('/api/users/delete-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ email: user.email }),
      });
      if (!response.ok) throw new Error('Failed to delete account.');
      setSuccess('Account deleted successfully.');
      localStorage.removeItem('authToken');
      navigate('/home');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEnable2FA = async () => {
    setError('');
    setSuccess('');
    try {
      const response = await fetch('/api/users/enable-2fa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ email: user.email }),
      });
      if (!response.ok) throw new Error('Failed to enable 2FA.');
      const data = await response.json();
      setQrCodeUrl(data.qrCodeUrl);
      setTwoFaKey(data.secretKey);
      setSuccess('2FA enabled successfully.');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDisable2FA = async () => {
    setError('');
    setSuccess('');
    try {
      const response = await fetch('/api/users/disable-2fa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ email: user.email }),
      });
      if (!response.ok) throw new Error('Failed to disable 2FA.');
      setQrCodeUrl(null);
      setTwoFaKey(null);
      setSuccess('2FA disabled successfully.');
    } catch (err) {
      setError(err.message);
    }
  };

  const openPopup = () => setShowPopup(true); 
  const closePopup = () => setShowPopup(false);

  return (
    <div className="w-full md:w-3/4 p-6 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold mb-4 text-center">Account Settings</h2>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
          {error}
        </div>
      )}
      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded mb-4">
          {success}
        </div>
      )}

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-1">Change Username</h3>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSubmit('me/username', { username: formData.newUsername }, 'Username updated successfully.');
        }}>
          <input
            type="text"
            name="newUsername"
            value={formData.newUsername}
            onChange={handleChange}
            placeholder="Enter new username"
            className="border p-2 rounded-lg w-full mb-2 text-text"
            disabled={!user.premium}
          />
          <button
            type="submit"
            className={`w-full p-2 rounded-lg transition-transform transform hover:scale-105 ${!user.premium ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary text-white'}`}
            disabled={!user.premium}
          >
            Change Username
          </button>
        </form>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-1">Change Email</h3>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSubmit('me/email', { email: formData.newEmail }, 'Email updated successfully.');
        }}>
          <input
            type="email"
            name="newEmail"
            value={formData.newEmail}
            onChange={handleChange}
            placeholder="Enter new email"
            className="border p-2 rounded-lg w-full mb-2 text-text"
          />
          <button type="submit" className="w-full p-2 rounded-lg bg-primary text-white transition-transform transform hover:scale-105">
            Change Email
          </button>
        </form>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-1">Change Password</h3>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSubmit('me/password', { password: formData.newPassword }, 'Password updated successfully.');
        }}>
          <input
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            placeholder="Enter new password"
            className="border p-2 rounded-lg w-full mb-2 text-text"
          />
          <button type="submit" className="w-full p-2 rounded-lg bg-primary text-white transition-transform transform hover:scale-105">
            Change Password
          </button>
        </form>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-1">Two-Factor Authentication (2FA)</h3>
        {!user.is2FAEnabled ? (
          <>
            <button onClick={handleEnable2FA} className="w-full p-2 rounded-lg bg-primary text-white transition-transform transform hover:scale-105">
              Enable 2FA
            </button>
            {qrCodeUrl && (
              <div className="mt-6">
                <h3 className="text-center text-lg font-semibold mb-4">Scan the QR Code to Enable 2FA</h3>
                <img src={qrCodeUrl} alt="QR code for 2FA setup" className="mx-auto" />
                <p className="text-center text-sm mt-2">
                  Or manually enter this key into your Authenticator app: <strong>{twoFaKey}</strong>
                </p>
              </div>
            )}
          </>
        ) : (
          <button onClick={handleDisable2FA} className="w-full p-2 rounded-lg bg-red-600 text-white transition-transform transform hover:scale-105">
            Disable 2FA
          </button>
        )}
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-1">Delete Account</h3>
        <button
          onClick={openPopup} 
          className="w-full p-2 rounded-lg bg-red-600 text-white transition-transform transform hover:scale-105"
        >
          Delete Account
        </button>
      </div>

      {showPopup && (
        <CustomPopup
          message="Are you sure you want to delete your account? This action cannot be undone and is instant."
          onConfirm={() => {
            handleDeleteAccount();  
            closePopup(); 
          }}
          onCancel={closePopup} 
        />
      )}
    </div>
  );
};

export default AccountSettings;
