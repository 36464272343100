import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import standardpfp from '../images/standardpfp.jpeg';
import FollowersList from '../components/FollowersList';
import decorations from '../components/decoration';

const UserProfile = () => {
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listType, setListType] = useState(null); 
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`/api/users/${username}`);
        if (!response.ok) {
          throw new Error(`User not found: ${response.status}`);
        }
        const data = await response.json();
        setUser(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [username]);

  const handleFollowToggle = async () => {
    try {
      const endpoint = isFollowing ? `/api/users/${username}/unfollow` : `/api/users/${username}/follow`;
      await fetch(endpoint, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      setIsFollowing(!isFollowing);
    } catch (err) {
      console.error('Failed to follow/unfollow user:', err);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  if (!user) return null;

  const {
    pfp,
    bg,
    bio,
    bgColor,
    xp,
    currentDecoration,
    premium,
    xpMonthly,
    xpWeekly,
    profileVisibility, 
  } = user;

  if (!profileVisibility) {
    return (
      <div className="max-w-4xl mx-auto p-4 sm:p-6">
        <div className="relative rounded-xl overflow-hidden shadow-lg w-full h-40 sm:h-60 lg:h-72">
          <img
            src={standardpfp} 
            alt="Private profile"
            className="absolute z-10 w-20 h-20 sm:w-28 sm:h-28 lg:w-32 lg:h-32 rounded-full"
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </div>

        <div className="w-full rounded-xl shadow-lg mt-8 sm:mt-12 p-4 sm:p-6 text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-text">Anonymous</h1>
          <p className="mt-4 text-sm sm:text-base text-text">This user has set their profile to private.</p>
          <button
            className={`mt-4 px-4 py-2 rounded ${
              isFollowing ? 'bg-red-500 text-white' : 'bg-accent text-text'
            }`}
            onClick={handleFollowToggle}
          >
            {isFollowing ? 'Unfollow' : 'Follow'}
          </button>
        </div>
      </div>
    );
  }

  const profilePic = pfp || standardpfp;
  const decorationArray = Array.isArray(decorations) ? decorations : Object.values(decorations);
  const decoration = decorationArray.find(deco => deco.key === currentDecoration);
  const isGif = bg && (bg.endsWith('.gif') || bg.endsWith('.mp4'));

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6">
      <div className="relative rounded-xl overflow-hidden shadow-lg w-full h-40 sm:h-60 lg:h-72">
        {bg ? (
          isGif ? (
            <img
              src={bg}
              alt="profile background gif"
              className="w-full h-full object-cover"
            />
          ) : (
            <div
              className="w-full h-full"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          )
        ) : (
          <div
            className="w-full h-full"
            style={{
              backgroundColor: bgColor || '#ff007c',
            }}
          />
        )}
        <div className="absolute inset-0 flex items-center justify-center">
          {decoration && (
            <img
              src={decoration.image}
              alt={`${currentDecoration} decoration`}
              className="absolute z-20 w-24 h-24 sm:w-32 sm:h-32 lg:w-40 lg:h-40"
            />
          )}
          {/* Profile Picture */}
          <img
            src={profilePic}
            alt="profile"
            className="relative z-10 w-20 h-20 sm:w-28 sm:h-28 lg:w-32 lg:h-32 rounded-full"
          />
        </div>
      </div>

      <div className="w-full rounded-xl shadow-lg mt-8 sm:mt-12 p-4 sm:p-6 text-center relative">
        <h1 className="text-2xl sm:text-3xl font-bold text-text">{username}</h1>
        
        {premium && (
          <span className="text-sm font-semibold text-yellow-500">Premium User</span>
        )}

        <button
          className={`absolute top-0 right-0 mt-2 mr-2 px-4 py-2 rounded ${
            isFollowing ? 'bg-red-500 text-white' : 'bg-primary text-text'
          }`}
          onClick={handleFollowToggle}
        >
          {isFollowing ? 'Unfollow' : 'Follow'}
        </button>

        <p className="mt-4 text-sm sm:text-base md:text-lg text-text">{bio || 'No bio available'}</p>

        <div className="flex justify-center mt-6 space-x-4">
          <button 
            className="bg-primary text-text px-4 py-2 rounded" 
            onClick={() => setListType('followers')}
          >
            Followers
          </button>
          <button 
            className="bg-primary text-text px-4 py-2 rounded" 
            onClick={() => setListType('following')}
          >
            Following
          </button>
        </div>
        
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-center">
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-text">{xp} XP</h3>
            <p className="text-sm text-text">Total XP</p>
          </div>
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-text">{xpMonthly} XP</h3>
            <p className="text-sm text-text">Monthly XP</p>
          </div>
          <div>
            <h3 className="text-lg sm:text-xl font-bold text-text">{xpWeekly} XP</h3>
            <p className="text-sm text-text">Weekly XP</p>
          </div>
        </div>

        <div className="flex flex-wrap justify-between mt-8">
          <div className="w-full md:w-1/2 p-4">
            <h2 className="text-xl sm:text-2xl font-bold text-text">Unlocked Themes</h2>
            <div className="flex flex-wrap justify-center mt-4">
              {user.unlockedThemes.map((theme, index) => (
                <span key={index} className="mx-2 p-2 bg-primary rounded-md text-text">
                  {theme.charAt(0).toUpperCase() + theme.slice(1)}
                </span>
              ))}
            </div>
          </div>
          <div className="w-full md:w-1/2 p-4">
            <h2 className="text-xl sm:text-2xl font-bold text-text">Purchased Decorations</h2>
            <div className="flex flex-wrap justify-center mt-4">
              {user.purchasedDecorations.map((decoration, index) => (
                <span key={index} className="mx-2 p-2 bg-primary rounded-md text-text">
                  {decoration.charAt(0).toUpperCase() + decoration.slice(1)}
                </span>
              ))}
            </div>
          </div>
        </div>

        {listType && (
          <FollowersList 
            listType={listType} 
            username={username} 
            onClose={() => setListType(null)} 
          />
        )}
      </div>
    </div>
  );
};

export default UserProfile;
