import React, { useState } from "react";
import ThemeToggle from "./ThemeToggle";
import logo from "../images/highres.png";
import { useNavigate } from "react-router-dom";
import Dropdown from "../components/Menu";
import { FiMenu, FiX } from "react-icons/fi";

const TopBar = () => {
  const navigateTo = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleRandomAnime = async () => {
    try {
      const response = await fetch("/api/anime/random");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const { id } = await response.json();
      navigateTo(`/anime/${id}`);
    } catch (err) {
      console.error("Failed to fetch a random anime:", err);
    }
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header className="flex items-center justify-between h-14 p-1 bg-opacity-30 backdrop-filter backdrop-blur-lg relative z-10 w-full shadow-md dark:shadow-lg">
      <div className="flex items-center">
        <img
          src={logo}
          alt="K-Anime Logo"
          onClick={() => navigateTo("/")}
          className="w-10 h-10 sm:w-12 sm:h-12 cursor-pointer transition-transform duration-200 ease-in-out hover:scale-105 ml-3"
        />
      </div>

      <nav className="hidden sm:flex flex-1 justify-left ml-10 space-x-4" role="navigation" aria-label="Main Navigation">
        <span
          onClick={() => navigateTo("/")}
          className="text-sm text-text cursor-pointer hover:text-accent transition-colors duration-200"
        >
          Home
        </span>
        <span
          onClick={() => navigateTo("/search")}
          className="text-sm text-text cursor-pointer hover:text-accent transition-colors duration-200"
        >
          Search
        </span>
        <span
          onClick={() => navigateTo("/leaderboard")}
          className="text-sm text-text cursor-pointer hover:text-accent transition-colors duration-200"
        >
          Leaderboard
        </span>
        <span
          onClick={() => navigateTo("/news")}
          className="text-sm text-text cursor-pointer hover:text-accent transition-colors duration-200"
        >
          News
        </span>
        <span
          onClick={handleRandomAnime}
          className="text-sm text-text cursor-pointer hover:text-accent transition-colors duration-200"
        >
          Random
        </span>
      </nav>

      <div className="flex items-center space-x-2">
        <ThemeToggle />
        
        <div className="hidden sm:block">
          <Dropdown />
        </div>

        <div className="sm:hidden">
          <button onClick={toggleMenu} aria-label="Toggle Menu" className="focus:outline-none">
            {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>
      </div>

      {menuOpen && (
        <nav
          className="absolute top-14 left-0 w-full bg-secondary bg-opacity-90 sm:hidden flex flex-col items-center py-4 space-y-4 shadow-lg"
          role="navigation"
          aria-label="Mobile Navigation"
        >
          <span
            onClick={() => {
              toggleMenu();
              navigateTo("/");
            }}
            className="text-lg text-text cursor-pointer hover:text-primary transition-colors duration-200"
          >
            Home
          </span>
          <span
            onClick={() => {
              toggleMenu();
              navigateTo("/search");
            }}
            className="text-lg text-text cursor-pointer hover:text-primary transition-colors duration-200"
          >
            Search
          </span>
          <span
            onClick={() => {
              toggleMenu();
              navigateTo("/leaderboard");
            }}
            className="text-lg text-text cursor-pointer hover:text-primary transition-colors duration-200"
          >
            Leaderboard
          </span>
          <span
            onClick={() => {
              toggleMenu();
              navigateTo("/news");
            }}
            className="text-lg text-text cursor-pointer hover:text-primary transition-colors duration-200"
          >
            News
          </span>
          <span
            onClick={() => {
              toggleMenu();
              handleRandomAnime();
            }}
            className="text-lg text-text cursor-pointer hover:text-primary transition-colors duration-200"
          >
            Random
          </span>
          <Dropdown mobileMode={true} closeMenu={toggleMenu} />
        </nav>
      )}
    </header>
  );
};

export default TopBar;
