import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import decorations from './decoration';

const DecorationSettings = () => {
  const { token } = useAuth();
  const [currentDecoration, setCurrentDecoration] = useState(null);
  const [ownedDecorations, setOwnedDecorations] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('/api/users/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data && data.purchasedDecorations) {
          setOwnedDecorations(data.purchasedDecorations);
          setCurrentDecoration(data.purchasedDecorations[0]);
        }
      } catch (err) {
        console.error('Fetch user data error:', err);
        setError('An unexpected error occurred while fetching user data.');
      }
    };

    fetchUserData();
  }, [token]);

  const updateDecoration = async (decorationKey) => {
    if (!ownedDecorations.includes(decorationKey)) {
      navigate('/shop');
      return;
    }

    try {
      const response = await fetch('/api/users/update-decoration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ decoration: decorationKey }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data && data.message === 'Decoration updated successfully!') {
        setCurrentDecoration(decorationKey);
        setSuccessMessage('Decoration updated successfully!');
        setTimeout(() => setSuccessMessage(null), 3000); 
      } else {
        throw new Error(data.message || 'Failed to update decoration');
      }
    } catch (err) {
      console.error('Update decoration error:', err);
      setError('An unexpected error occurred while updating the decoration.');
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4 md:p-8 bg-secondary bg-opacity-60 rounded-lg shadow-lg">
      <h2 className="text-3xl font-semibold mb-6 text-center">Decorations Settings</h2>
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
      {successMessage && <p className="text-green-500 mb-4 text-center">{successMessage}</p>}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {decorations.map((decoration) => {
          const isOwned = ownedDecorations.includes(decoration.key);

          return (
            <div
              key={decoration.key}
              className={`decoration-option p-4 border rounded-lg cursor-pointer transition-transform transform hover:scale-105 ${
                currentDecoration === decoration.key ? 'border-primary' : 'border-gray-300'
              } shadow-md`}
              onClick={() => updateDecoration(decoration.key)}
            >
              <img
                src={decoration.image}
                alt={decoration.name}
                className="w-full h-28 object-cover rounded-md"
              />
              <h3 className="text-center mt-2 font-semibold text-sm">{decoration.name}</h3>

              {isOwned ? (
                <button className="mt-2 w-full py-2 bg-primary text-white rounded text-xs">
                  {currentDecoration === decoration.key ? 'Applied' : 'Apply'}
                </button>
              ) : (
                <button
                  className="mt-2 w-full py-2 bg-secondary text-white rounded text-xs"
                  onClick={() => navigate('/shop')}
                >
                  Unlock
                </button>
              )}

              {currentDecoration === decoration.key && (
                <p className="text-green-500 mt-2 text-center text-xs">Applied</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DecorationSettings;
