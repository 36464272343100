// SettingsContent.js
import React from 'react';
import AccountSettings from './AccountSettings';
import ProfileSettings from './ProfileSettings';
import Themes from './Appearance';
import DecorationSettings from './Decorations';
import SocialMediaSettings from './SocialMedia';
import PrivacySettings from './PrivacySettings';

const SettingsContent = ({ selectedCategory }) => {
  const renderContent = () => {
    switch (selectedCategory) {
      case 'account':
        return <AccountSettings />;
      case 'privacy':
        return <PrivacySettings />;
      case 'profile':
        return <ProfileSettings />;
      case 'themes':
        return <Themes />;
      case 'decorations':
        return <DecorationSettings />;
      case 'socialmedia':
        return <SocialMediaSettings />;
      default:
        return <div>Select a category</div>;
    }
  };

  return (
    <div className="w-full md:w-3/4 p-6" style={{ marginTop: '-35px' }}>
      {renderContent()}
    </div>
  );
};

export default SettingsContent;
